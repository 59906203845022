const Fairies = [
  {
      "image": "Abigail 9781843626343.jpg",
      "name": "Abigail",
      "type": "the Breeze Fairy",
      "series": "The Weather Fairies",
      "description": "Jack Frost has promised not to trouble the Rainbow Fairies again, but he didn’t say anything about the Weather Fairies! Now he has stolen the feathers from Doodle, the weather-vane cockerel in charge of the weather. It’s up to Rachel and Kirsty to get each of the feathers back!",
  },

  {
      "image": "Adele 9781408315903.jpg",
      "name": "Adele",
      "type": "the Singing Coach Fairy",
      "series": "The Pop Star Fairies",
      "description": "Rachel and Kirsty are in for a surprise when their fairy friends whisk them off to the Fairyland Music Festival, only for Jack Frost and his goblins to strut onto the stage! The naughty gang have stolen the Pop Star Fairies' magical notes, and Jack Frost intends to use them to become the biggest star in the world. They have to get them back - or concerts everywhere will be ruined!",
  },

  {
      "image": "Aisha 9781408355121.jpg",
      "name": "Aisha",
      "type": "the Astronaut Fairy",
      "series": "The Discovery Fairies",
      "description": "Rachel and Kirsty are so excited to meet the Discovery Fairies, who look after some of the most exciting jobs in the world. But when Jack Frost steals Aisha the Astronaut Fairy’s magical item, the astronauts everywhere lose their mojo! Can the girls help Aisha defeat him in a truly out-of-this-world adventure?",
  },

  {
      "image": "Alesha 9781408312889.jpg",
      "name": "Alesha",
      "type": "the Acrobat Fairy",
      "series": "The Showtime Fairies",
      "description": "Jack Frost has stolen the Showtime Fairies’ magic stars, which means everyone is losing their special talent! Can Rachel and Kirsty get the stars back before the Tippington Variety Show is ruined?",
  },

  {
      "image": "Alexa 9781408316771.jpg",
      "name": "Alexa",
      "type": "the Fashion Reporter Fairy",
      "series": "The Fashion Fairies",
      "description": "Rachel and Kirsty are excited to be entering a charity fashion show where children get to show off their own designs. Unfortunately, Jack Frost thinks everyone in the world should dress like him, and steals the Fashion Fairies’ seven magical items to put his naughty plan into action! The fashion reporter from the Tippington Times is having a terrible day – she muddles up her words, forgets her notepad and generally gets in a fluster. Can Rachel and Kirsty help her out?",
  },

  {
      "image": "Alexandra 9781408329757.jpg",
      "name": "Alexandra",
      "type": "the Royal Baby Fairy",
      "series": "Specials",
      "description": "The whole of Fairyland is very excited - there's going to be a new royal baby! But when Foster the stork fails to deliver the new bundle of joy on time, the Royal Baby Fairy asks friends of the fairies, Rachel Tate and Kirsty Walker, for their help. Just where can the extra-special baby be?",
  },

  {
      "image": "Alice 9781846168932.jpg",
      "name": "Alice",
      "type": "the Tennis Fairy",
      "series": "The Sporty Fairies",
      "description": "The Sporty Fairies are in need of Rachel and Kirsty’s help. Everyone in Fairyland is preparing for the Fairy Olympics, but Jack Frost and his goblins have stolen the magic sporty items so they can win by cheating! What’s more, with the items missing, everyone in the human world is playing sports really badly, and so the human Olympics is in danger of being ruined too! Rachel and Kirsty love playing tennis, but with Alice’s Magic Tennis Ball missing, they can’t seem to hit one shot! The girls must help Alice find the goblins before it’s too late…",
  },

  {
      "image": "Alison 9781408333921.jpg",
      "name": "Alison",
      "type": "the Art Fairy",
      "series": "The School Days Fairies",
      "description": "Rachel and Kirsty can’t wait to go to school together for a week! But trouble starts when Jack Frost steals the School Days Fairies magical star badges. Can the girls get it back before everyone’s lessons go horribly wrong?",
  },

  {
      "image": "Ally 9781408308158.jpg",
      "name": "Ally",
      "type": "the Dolphin Fairy",
      "series": "The Ocean Fairies",
      "description": "Rachel and Kirsty are visiting Kirsty’s grandmother when they are whisked away to Fairyland for the yearly Ocean Gala. Here, Shannon the Ocean Fairy plays her Magical Golden Conch Shell to ensure that the seas and oceans remain peaceful and ordered for the year ahead. Everything is going well – until Jack Frost snatches the conch shell and breaks it into seven pieces. Rachel and Kirsty, along with the Ocean Fairies, must get the pieces back before chaos takes over the oceans!",
  },

  {
      "image": "Alyssa 9781408339558.jpg",
      "name": "Alyssa",
      "type": "the Snow Queen Fairy",
      "series": "Specials",
      "description": "Alyssa the Snow Queen Fairy makes sure that everyone is happy throughout winter in both the human and fairy worlds. But when nasty Jack Frost steals her magical objects, everything becomes an icy misery! Can Rachel and Kirsty help get them back before Fairyland freezes over? There’s a magical snowflake, an enchanted mirror and an everlasting rose in this book. Read it to see if Rachel, Kirsty and Alyssa can find them!",
  },

  {
      "image": "Amber 9781843620174.jpg",
      "name": "Amber",
      "type": "the Orange Fairy",
      "series": "The Rainbow Fairies",
      "description": "Fairyland is home to seven colorful sisters. Together, they are the Rainbow Fairies! They keep Fairyland dazzling and bright. But when evil Jack Frost sends them far away, the sisters are in big trouble. If they don't return soon, Fairyland is doomed to be gray forever! Rachel and Kirsty have already found one Rainbow Fairy, but now Amber the Orange Fairy is trapped in a seashell! Can they rescue her, too?",
  },

  {
      "image": "Amelia 9781408312919.jpg",
      "name": "Amelia",
      "type": "the Singing Fairy",
      "series": "The Showtime Fairies",
      "description": "Rachel and Kirsty must find Amelia's magic star. Without it, nobody can sing in tune and the auditions for the Tippington Variety Show will be a disaster! Could the badly behaved, green-footed band Thrillz and Chillz have something to do with the missing star...",
  },

  {
      "image": "Amelie 9781408308165.jpg",
      "name": "Amelie",
      "type": "the Seal Fairy",
      "series": "The Ocean Fairies",
      "description": "Rachel and Kirsty are visiting Kirsty’s grandmother when they are whisked away to Fairyland for the yearly Ocean Gala. Here, Shannon the Ocean Fairy plays her Magical Golden Conch Shell to ensure that the seas and oceans remain peaceful and ordered for the year ahead. Everything is going well – until Jack Frost snatches the conch shell and breaks it into seven pieces. Rachel and Kirsty, along with the Ocean Fairies, must get the pieces back before chaos takes over the oceans!",
  },

  {
      "image": "Amy 9781843629573.jpg",
      "name": "Amy",
      "type": "the Amethyst Fairy",
      "series": "The Jewel Fairies",
      "description": "Disaster has struck Fairyland! Jack Frost has stolen all the jewels from Queen Titania’s crown. Without them, the fairies’ magic dust is losing its power. Rachel and Kirsty have to help the Jewel Fairies to get the jewels back… before it’s too late.  In this exciting adventure Amy's amethyst needs to be found, but can Rachel and Kirsty reach it before the pesky goblins get their warty hands on it...",
  },

  {
      "image": "Angelica 9781408316887.jpg",
      "name": "Angelica",
      "type": "the Angel Fairy",
      "series": "Specials",
      "description": "Angelica the Angel Fairy makes sure we all treat each other kindly at Christmas time. But when Jack Frost steals her magic items, everyone from Brownies to the residents of an old folks’ home start acting up! Can Rachel and Kirsty restore order in time for Christmas?",
  },

  {
      "image": "Anna 9781408327999.jpg",
      "name": "Anna",
      "type": "the Arctic Fox Fairy",
      "series": "The Baby Animal Rescue Fairies",
      "description": "Kirsty Tate and Rachel Walker are helping out at the Wild Woods Nature Reserve. They’re having a great time meeting cute animals and making friends with The Baby Animal Rescue Fairies. But when Jack Frost decides he wants baby animals for his own personal zoo, he steals the fairies’ magical key rings. Can the girls help the fairies get their key rings back before the baby animals are trapped inside the Ice Lord’s zoo for ever?",
  },

  {
      "image": "Annabelle 9781408331439.jpg",
      "name": "Annabelle",
      "type": "the Drawing Fairy",
      "series": "The Magical Craft Fairies",
      "description": "Rachel and Kirsty return to Rainspell Island for Crafts Week. They’re so excited try all of the workshops. But after the Magical Crafts Fairies’ items are stolen, all types of crafts are in chaos. Jewellery will keep breaking, artists won’t be able to draw properly and paint will come out grey! Can they help the Magical Crafts Fairies get them back before Crafts Week is ruined?",
  },

  {
      "image": "Annie 9781408355169.jpg",
      "name": "Annie",
      "type": "the Detective Fairy",
      "series": "The Discovery Fairies",
      "description": "Rachel and Kirsty are so excited to meet the Discovery Fairies, who look after some of the most exciting jobs in the world. But when Jack Frost steals Annie the Detective Fairy’s magical item, detectives everywhere run out of clues! Can the girls help Annie get it back and help solve mysteries everywhere?",
  },

  {
      "image": "Anya 9781408312957.jpg",
      "name": "Anya",
      "type": "the Cuddly Creatures Fairy",
      "series": "The Princess Fairies",
      "description": "Rachel and Kirsty are so excited to spend the week at the Golden Palace. They're going to live like real princesses! But before long, they discover that something is terribly wrong. The Princess Fairies' magic tiaras are missing! Without them, both Fairyland and the human world are a royal mess. Something is wrong with the animals at the petting zoo and royal stables! Could it be because Anya the Cuddly Creatures Fairy's tiara is missing?",
  },

  {
      "image": "Ariana 9781408339534.jpg",
      "name": "Ariana",
      "type": "the Firefighter Fairy",
      "series": "The Helping Fairies",
      "description": "Ariana the Firefighter Fairy makes sure firefighters have everything the need to keep people safe. But when Jack Frost steals her magical helmet, everything goes up in smoke! Can Rachel and Kirsty help their fairy friend keep things cool?",
  },

  {
      "image": "Ashley 9781408303498.jpg",
      "name": "Ashley",
      "type": "the Dragon Fairy",
      "series": "The Magical Animal Fairies",
      "description": "The magical animal fairies look after seven young magical animals, and train them to use their powers wisely for the good of Fairyland and the human world. But the creatures have been stolen! Can Rachel and Kirsty find them before they are lost forever?",
  },

  {
      "image": "Ava 9781408309063.jpg",
      "name": "Ava",
      "type": "the Sunset Fairy",
      "series": "The Twilight Fairies",
      "description": "Rachel and Kirsty are thrilled to be at Camp Stargaze. But Jack Frost has stolen the Twilight Fairies' magical bags, causing night-time chaos! Can the girls help Ava the Sunset Fairy find her bag of sunbeam dust, so the sun can finally set?",
  },

  {
      "image": "Bea 9781408362365.jpg",
      "name": "Bea",
      "type": "the Buddha Day Fairy",
      "series": "Specials",
      "description": "Join Rachel, Kirsty and the Festival Fairies as they help to make special celebrations everywhere run smoothly with the help of fairy magic.  Bea the Buddha Day Fairy looks after the Buddhist festival, but when naughty Jack Frost steals her precious candle, celebrations of Buddha's birthday run into difficulties. Can the girls help Bea save the day?",
  },

  {
      "image": "Becky 9781408340561.jpg",
      "name": "Becky",
      "type": "the Best Friend Fairy",
      "series": "Specials",
      "description": "Everyone knows Rachel and Kirsty are best friends! But when nasty Jack Frost steals Becky the Best Friend Fairy's magical objects, BFFs everywhere start having problems. Can the girls help Becky get them back and put things right? There's a heart trinket box, a photo album and a BFF charm necklace in this book. Read on to see if Rachel, Kirsty and Becky can find them!",
  },

  {
      "image": "Bella 9781846161704.jpg",
      "name": "Bella",
      "type": "the Bunny Fairy",
      "series": "The Pet Keeper Fairies",
      "description": "Fairyland is in uproar! Jack Frost has stolen the Pet Fairies magical pets. Without them, the Pet Fairies can't ensure that pets in the human world find the right owners. And now the pets have escaped from Jack Frost's ice castle and are roaming the human world! Rachel and Kirsty have to help get the pets back...before it's too late.",
  },

  {
      "image": "Belle 9781408308103.jpg",
      "name": "Belle",
      "type": "the Birthday Fairy",
      "series": "Specials",
      "description": "Belle the Birthday Fairy's job is to make sure that all birthdays in Fairyland and the human world are filled with joy and laughter! But there is one mean creature who is incapable of enjoying even this special day...Jack Frost! He has a birthday coming up but as he's very vain he decides that he is not going to celebrate it all...and if he's not going to enjoy his birthday then neither will anyone else! And so he steals Belle's magical items, and hides them in cunning places, causing chaos for birthdays everywhere. Will Rachel and Kirsty be able to help Belle find her items, or are birthdays ruined forever...?",
  },

  {
      "image": "Bethany 9781846164903.jpg",
      "name": "Bethany",
      "type": "the Ballet Fairy",
      "series": "The Dance Fairies",
      "description": "Jack Frost's naughty goblins have stolen the Dance Fairies' magic ribbons and are hiding in the human world. Everywhere dance is being ruined. Rachel and Kirsty must help Bethany but the goblins' new powers won't make it easy.",
  },

  {
      "image": "Billie 9781408345184.jpg",
      "name": "Billie",
      "type": "the Baby Goat Fairy",
      "series": "The Baby Farm Animal Fairies",
      "description": "Rachel and Kirsty are going to the Fluttering Fairyland Farm to meet the Baby Farm Animal Fairies. But when nasty Jack Frost casts a spell on the fairies' special pets, farm animals everywhere start behaving very strangely! Can the girls help the Baby Farm Animal Fairies put things right?",
  },

  {
      "image": "Bobbi 9781408349571.jpg",
      "name": "Bobbi",
      "type": "the Bouncy Castle Fairy",
      "series": "The Fun Fair Fairies",
      "description": "Kirsty is so excited to visit Rachel for a weekend and even more excited to visit The Fernandos' Fabulous Funfair! But Jack Frost wants a funfair all to himself and he's stolen the Funfair Fairies magic keyring to make it happen. Can Rachel and Kirsty defeat him, before the funfair is totally wrecked!",
  },

  {
      "image": "Bonnie 9781408355220.jpg",
      "name": "Bonnie",
      "type": "the Bike-Riding Fairy",
      "series": "The After School Sports Fairies",
      "description": "At the Tippington Leisure Centre, there are all sorts of sports to enjoy! But when Jack Frost steals Bonnie the Bike-Riding Fairy's magical object, the fun plans are knocked off balance. Rachel and Kirsty must find it, before the magic of cycling is lost forever!",
  },

  {
      "image": "Brianna 9781408363188.jpg",
      "name": "Brianna",
      "type": "the Bee Fairy",
      "series": "Specials",
      "description": "Brianna the Bee Fairy helps to look after all the bees in Fairyland and the real world. But when her special items are stolen, the bees are in danger! Can Rachel and Kirsty help Brianna to save the bees and protect their homes from destruction?",
  },

  {
      "image": "Brooke 9781408316795.jpg",
      "name": "Brooke",
      "type": "the Photographer Fairy",
      "series": "The Fashion Fairies",
      "description": "Rachel and Kirsty are excited to be entering a charity fashion show where children get to show off their own designs. Unfortunately, Jack Frost thinks everyone in the world should dress like him, and steals the Fashion Fairies' seven magical items to put his naughty plan into action! Rachel and Kirsty must help the Fashion Fairies get them back, so that people everywhere can continue to look good and feel great.",
  },

  {
      "image": "Caitlin 9781408303559.jpg",
      "name": "Caitlin",
      "type": "the Ice Bear Fairy",
      "series": "The Magical Animal Fairies",
      "description": "The Magical Animal Fairies look after seven young Magical Animals, and train them to use their powers wisely, for the good of Fairyland and the human world. But the animals have been stolen! Can Rachel and Kirsty help the Magical Animals before their powers cause chaos?",
  },

  {
      "image": "Callie 9781408355268.jpg",
      "name": "Callie",
      "type": "the Climbing Fairy",
      "series": "The After School Sports Fairies",
      "description": "Callie the Climbing Fairy's special magic inspires everyone to reach great heights. But when Jack Frost steals her magical item, climbers everywhere start getting dizzy! Can Rachel and Kirsty defeat him and help Callie get back on top?",
  },

  {
      "image": "Camilla 9781408352465.jpg",
      "name": "Camilla",
      "type": "the Christmas Present Fairy",
      "series": "Specials",
      "description": "Best friends Rachel and Kirsty are very excited to give each other their Christmas presents! But when Jack Frost steals Camilla the Christmas Present Fairy's magical objects, the magic of giving is in danger. Can the girls help get Camilla's items back and save Christmas for both the human and fairy worlds?",
  },

  {
      "image": "Cara 9781408355084.jpg",
      "name": "Cara",
      "type": "the Coding Fairy",
      "series": "Specials",
      "description": "Best friends Rachel and Kirsty are very excited to be going to a coding convention! They can't wait to start building their own programmes and apps. But naughty Jack Frost has other plans - to steal the magic of coding for himself!",
  },

  {
      "image": "Carly 9781408327890.jpg",
      "name": "Carly",
      "type": "the Schoolfriend Fairy",
      "series": "Specials",
      "description": "Rachel Walker and Kirsty Tate are very excited about taking part in a school competition! There's going to be a spelling bee and a science contest, followed by a dazzling disco. But Jack Frost and his goblins soon arrive...Can the girls help Carly the Schoolfriend Fairy keep the competition safe and fun?",
  },

  {
      "image": "Carrie 9781408304792.jpg",
      "name": "Carrie",
      "type": "the Snow Cap Fairy",
      "series": "The Green Fairies",
      "description": "Jack Frost has stolen Carrie the Snow Cap Fairy's wand! Without it, how can she help humans stop the snow caps from melting? Rachel and Kirsty must persuade icy Jack Frost to hand it over - after all, a warmer world would be very bad for him indeed!",
  },

  {
      "image": "Catherine 9781408339466.jpg",
      "name": "Catherine",
      "type": "the Fashion Princess Fairy",
      "series": "Specials",
      "description": "Catherine the Fashion Princess Fairy helps make sure princesses everywhere have the perfect outfits for their royal functions. But when Jack Frost and his goblins steal her magical objects, all sorts of things start to go wrong! Can Rachel and Kirsty help get them back before fashion disasters strike the human and fairy worlds?",
  },

  {
      "image": "Charlie 9781846164606.jpg",
      "name": "Charlie",
      "type": "the Sunflower Fairy",
      "series": "The Petal Fairies",
      "description": "The Petal Fairies have a very important job - together with their magic petals they help to make sure that flowers grow and bloom in the human world. But after Jack Frost and his goblins steal - and lose - the magic petals Rachel and Kirsty must help find them before the goblins get their hands on them!",
  },

  {
      "image": "Charlotte 9781408342381.jpg",
      "name": "Charlotte",
      "type": "the Baby Princess Fairy",
      "series": "Specials",
      "description": "Charlotte the Royal Princess Fairy helps make sure that all royal babies are happy, comfortable and snug in their cribs. But when Jack Frost steals her magical lion soft toy, babies everywhere are up all night crying! Can Rachel and Kirsty help get it back and stop the palace pandemonium?",
  },

  {
      "image": "Chelsea 9781408354988.jpg",
      "name": "Chelsea",
      "type": "the Chimpanzee Fairy",
      "series": "The Endangered Animals Fairies",
      "description": "Rachel and Kirsty are going to a very special safari park! They are very excited to help the endangered animals who live there. But naughty Jack Frost wants to steal the animals for himself! Can the girls help the Endangered Animals Fairies stop him?",
  },

  {
      "image": "Cherry 9781843628187.jpg",
      "name": "Cherry",
      "type": "the Cake Fairy",
      "series": "The Party Fairies",
      "description": "It's Queen Titania's and King Oberon's 1000th jubilee, and the seven Party Fairies are in charge of making the celebratory party extra-special! However, naughty Jack Frost has stolen each of the seven Party Fairies' magical party bags... if he succeeds the Fairyland celebrations will be ruined! Can Rachel and Kirsty help rescue the party bags so the magnificent jubilee cake can be finished in time...?",
  },

  {
      "image": "Cheryl 9781408309155.jpg",
      "name": "Cheryl",
      "type": "the Christmas Tree Fairy",
      "series": "Specials",
      "description": "Every December, Cheryl the Christmas Tree Fairy makes sure that everything is in place for a truly magical Christmas! But when her special Christmas tree goes missing, everything is thrown into chaos. Can Rachel and Kirsty help Cheryl and ensure that the festive season is fabulous for everyone?",
  },

  {
      "image": "Chloe 9781843629566.jpg",
      "name": "Chloe",
      "type": "the Topaz Fairy",
      "series": "The Jewel Fairies",
      "description": "Disaster has struck Fairyland! Jack Frost has stolen all the jewels from Queen Titania's crown. Without them, the fairies' magic dust is losing its power.  Chloe the Topaz Fairy must outwit the pesky goblins and rescue her jewel, with some help from Rachel and Kirsty!",
  },

  {
      "image": "Chrissie 9781846165061.jpg",
      "name": "Chrissie",
      "type": "the Wish Fairy",
      "series": "Specials",
      "description": "Chrissie the Wish Fairy has a very important job - each year, she comes to the human world to hide her three magical wish items so that Christmas wishes come true. She loves her visits, but this year it's not entirely fun - she has to protect her magic objects from the goblins! Jack Frost is eager to get his hands on the items and make some wishes himself. Can Rachel and Kirsty come to Chrissie's aid before it's too late?",
  },

  {
      "image": "Christina 9781408347317.jpg",
      "name": "Christina",
      "type": "the Winter Wonderland Fairy",
      "series": "Specials",
      "description": "Rachel and Kirsty are very excited to spending Christmas together. But when Jack Frost steals Christina the Winter Wonderland Fairies magical objects, the magic of winter is under threat! Can the girls and Christina help get them back and save Christmas for everyone?",
  },

  {
      "image": "Clara 9781408324998.jpg",
      "name": "Clara",
      "type": "the Chocolate Fairy",
      "series": "The Sweet Fairies",
      "description": "Rachel and Kirsty are really excited - Kirsty's aunt works at Candy Land, and has brought them a big bag of sweets! But they all taste awful... Can the girls help the Sweet Fairies and stop Jack Frost so that sweets taste delicious again?",
  },

  {
      "image": "Clare 9781408342701.jpg",
      "name": "Clare",
      "type": "the Caring Fairy",
      "series": "The Friendship Fairies",
      "description": "Everyone knows Rachel and Kirsty are best friends. But when Jack Frost steals the Friendship Fairies' magical objects, the special bond of BFFs everywhere is at risk! Can they fight the Ice Lord and his goblin minions to restore the magic of friendship in both the Fairyland and worlds?",
  },

  {
      "image": "Claudia 9781408316757.jpg",
      "name": "Claudia",
      "type": "the Accessories Fairy",
      "series": "The Fashion Fairies",
      "description": "Rachel and Kirsty are excited to be entering a charity fashion show where children get to show off their own designs. Unfortunately, Jack Frost thinks everyone in the world should dress like him, and steals the Fashion Fairies' seven magical items to put his naughty plan into action! Rachel and Kirsty must help the Fashion Fairies get them back, so that people everywhere can continue to look good and feel great.",
  },

  {
      "image": "Coco 9781408324981.jpg",
      "name": "Coco",
      "type": "the Cupcake Fairy",
      "series": "The Sweet Fairies",
      "description": "Rachel and Kirsty are really excited - Kirsty's aunt works at Candy Land, and has brought them a big bag of sweets! But they all taste awful... Can the girls help the Sweet Fairies and stop Jack Frost so that sweets taste delicious again?",
  },

  {
      "image": "Coral 9781408304778.jpg",
      "name": "Coral",
      "type": "the Reef Fairy ",
      "series": "The Green Fairies",
      "description": "Rachel and Kirsty are delighted when Coral the Reef Fairy takes them on a magical underwater journey! However, they soon meet Jack Frost's goblins, who are damaging a precious coral reef. Can the girls and Coral stop them before the beautiful reef is ruined?",
  },

  {
      "image": "Courtney 9781408308219.jpg",
      "name": "Courtney",
      "type": "the Clownfish Fairy",
      "series": "The Ocean Fairies",
      "description": "Rachel and Kirsty are visiting Kirsty's grandmother when they are whisked away to Fairyland for the yearly Ocean Gala. Here, Shannon the Ocean Fairy plays her Magical Golden Conch Shell to ensure that the seas and oceans remain peaceful and ordered for the year ahead. Everything is going well - until Jack Frost snatches the conch shell and breaks it into seven pieces. Rachel and Kirsty, along with the Ocean Fairies, must get the pieces back before chaos takes over the oceans!",
  },

  {
      "image": "Crystal 9781843626336.jpg",
      "name": "Crystal",
      "type": "the Snow Fairy",
      "series": "The Weather Fairies",
      "description": "Jack Frost has promised not to trouble the Rainbow Fairies again, but he didn’t say anything about the Weather Fairies! Now he has stolen the feathers from Doodle, the weather-vane cockerel in charge of the weather. It’s up to Rachel and Kirsty to get each of the feathers back!",
  },

  {
      "image": "Daisy 9781408336519.jpg",
      "name": "Daisy",
      "type": "the Festival Fairy",
      "series": "Specials",
      "description": "Daisy the Festival Fairy makes sure that every summer is full of exciting things to do. But this year, a strange breeze is in the air, and an icy snow storm threatens to ruin all the fun plans. Can Rachel and Kirsty help stop Jack Frost before festivals everywhere are ruined?",
  },

  {
      "image": "Danielle 9781846164620.jpg",
      "name": "Danielle",
      "type": "the Daisy Fairy",
      "series": "The Petal Fairies",
      "description": "The Petal Fairies have a very important job - together with their magic petals they help to make sure that flowers grow and bloom in the human world. But after Jack Frost and his goblins steal - and lose - the magic petals Rachel and Kirsty must help find them before the goblins get their hands on them!",
  },

  {
      "image": "Danni 9781408300282.jpg",
      "name": "Danni",
      "type": "the Drum Fairy",
      "series": "The Music Fairies",
      "description": "The Music Fairies desperately need Rachel's and Kirsty's help! Jack Frost and his naughty goblins have stolen the fairies' Magical Musical Instruments, which means that music is being ruined for everyone! Jack Frost plans to use the instruments to help him win a national talent competition in the human world, and with the help of the enchanted instruments, he's bound to win. If this happens, humans will find out about Fairyland and then all the fairies will be in danger!",
  },

  {
      "image": "Darcey 9781408312896.jpg",
      "name": "Darcey",
      "type": "the Dance Diva Fairy",
      "series": "The Showtime Fairies",
      "description": "Jack Frost has stolen the Showtime Fairies' magic stars, which means everyone is losing their special talent! Can Rachel and Kirsty get the stars back before the Tippington Variety Show is ruined?",
  },

  {
      "image": "Debbie 9781408345108.jpg",
      "name": "Debbie",
      "type": "the Duckling Fairy",
      "series": "The Baby Farm Animal Fairies",
      "description": "Rachel and Kirsty are going to the Fluttering Fairyland Farm to meet the Baby Farm Animal Fairies. But when nasty Jack Frost casts a spell on the fairies' special pets, farm animals everywhere start behaving very strangely! Can the girls help the Baby Farm Animal Fairies put things right?",
  },

  {
      "image": "Deena 9781408362341.jpg",
      "name": "Deena",
      "type": "the Diwali Fairy",
      "series": "The Festival Fairies",
      "description": "When Deena the Diwali Fairy's diya lamp is stolen by naughty Jack Frost, Diwali celebrations everywhere are at risk. Rachel and Kirsty must help Deena find her magical object!",
  },

  {
      "image": "Demi 9781408312940.jpg",
      "name": "Demi",
      "type": "the Dressing-Up Fairy",
      "series": "The Princess Fairies",
      "description": "Rachel and Kirsty have been invited to meet the seven nieces of Queen Titania and King Oberon - the Princess Fairies! But when Rachel and Kirsty arrive in Fairyland things soon go wrong, Jack Frost and his wicked goblins steal the princesses' tiaras! The tiaras contain special fairy magic that look after happiness and fun in both the human and fairy worlds. They need to get them back so everyone can be happy once again!",
  },

  {
      "image": "Destiny 9781408304730.jpg",
      "name": "Destiny",
      "type": "the Pop Star Fairy",
      "series": "Specials",
      "description": "Destiny the Pop Star Fairy looks after all pop stars in Fairyland and the human world. So when things start to go wrong for The Angels, Rachel and Kirsty's favourite pop group, Destiny needs the girls' help to fix things before The Angels' charity Christmas concert is ruined! Are Jack Frost and his band of naughty goblins causing trouble again...?",
  },

  {
      "image": "Edie 9781408304761.jpg",
      "name": "Edie",
      "type": "the Garden Fairy",
      "series": "The Green Fairies",
      "description": "Rachel and Kirsty are excited for their holiday - but their hearts sink when they see their favourite beach is covered in litter. They visit the King and Queen of Fairyland , who introduce them to seven trainee fairies, the Green Fairies. But then Jack Frost turns up to steal their new wands! Rachel and Kirsty will have to get them back if they want the world to be clean...",
  },

  {
      "image": "Eleanor 9781408336731.jpg",
      "name": "Eleanor",
      "type": "the Snow White Fairy",
      "series": "The Fairytale Fairies",
      "description": "Rachel and Kirsty are having a wonderful time at the Fairytale Festival. But Jack Frost has stolen Fairytale Fairies' magical objects, and now Fairytale characters are lost in the human world! Can Rachel and Kirsty help find them and keep fairytales everywhere magical?",
  },

  {
      "image": "Elisa 9781408312964.jpg",
      "name": "Elisa",
      "type": "the Adventure Fairy",
      "series": "The Princess Fairies",
      "description": "Rachel and Kirsty have been invited to meet the seven nieces of Queen Titania and King Oberon - the Princess Fairies! But when Rachel and Kirsty arrive in Fairyland things soon go wrong, Jack Frost and his wicked goblins steal the princesses' tiaras! The tiaras contain special fairy magic that look after happiness and fun in both the human and fairy worlds. They need to get them back so everyone can be happy once again!",
  },

  {
      "image": "Elisha 9781408362402.jpg",
      "name": "Elisha",
      "type": "the Eid Fairy",
      "series": "The Festival Fairies",
      "description": "Elisha the Eid Fairy looks after the Muslim festival of Eid, but when naughty Jack Frost steals her magical Eid lantern, everyone's celebrations go wrong. Can the girls help Elisha save the day?",
  },

  {
      "image": "Elizabeth 9781408323847.jpg",
      "name": "Elizabeth",
      "type": "the Jubilee Fairy",
      "series": "Specials",
      "description": "Elizabeth the Jubilee Fairy is in charge of making every jubilee perfect! But when Jack Frost steals Elizabeth's Diamond Sceptre, both fairy and human celebrations are headed for catastrophe. Can Rachel and Kirsty help Elizabeth find the sceptre so kings and queens everywhere can celebrate in style?",
  },

  {
      "image": "Ella 9781846164644.jpg",
      "name": "Ella",
      "type": "the Rose Fairy",
      "series": "The Petal Fairies",
      "description": "The Petal Fairies have a very important job - together with their magic petals they help to make sure that flowers grow and bloom in the human world. But after Jack Frost and his goblins steal - and lose - the magic petals Rachel and Kirsty must help find them before the goblins get their hands on them!",
  },

  {
      "image": "Elle 9781408340325.jpg",
      "name": "Elle",
      "type": "the Thumbelina Fairy",
      "series": "The Storybook Fairies",
      "description": "Rachel and Kirsty are very excited to be going to the Wetherbury Storytelling Festival! But when mean Jack Frost steals the Storybook Fairies' magical objects, all the stories start getting mixed up. Can the girls help their fairy friends put things right?",
  },

  {
      "image": "Ellen 9781408350775.jpg",
      "name": "Ellen",
      "type": "the Explorer Fairy",
      "series": "Specials",
      "description": "Ellen the Explorer Fairy loves nothing more than adventuring across Fairyland and discovering its secrets! Join Rachel and Kirsty as they are whisked away by some magical fairy dust for their most exciting journey yet.",
  },

  {
      "image": "Ellie 9781408300305.jpg",
      "name": "Ellie",
      "type": "the Guitar Fairy",
      "series": "The Music Fairies",
      "description": "The Music Fairies desperately need Rachel's and Kirsty's help! Jack Frost and his naughty goblins have stolen the fairies' Magical Musical Instruments, which means that music is being ruined for everyone! Jack Frost plans to use the instruments to help him win a national talent competition in the human world, and with the help of the enchanted instruments, he's bound to win. If this happens, humans will find out about Fairyland and then all the fairies will be in danger! Ellie the Guitar Fairy needs Rachel's and Kirsty's help to find her Magic Guitar. They must come up with a plan to to take it back from the sneaky goblins...",
  },

  {
      "image": "Elodie 9781408345146.jpg",
      "name": "Elodie",
      "type": "the Lamb Fairy",
      "series": "The Baby Farm Animal Fairies",
      "description": "Rachel and Kirsty are going to the Fluttering Fairyland Farm to meet the Baby Farm Animal Fairies. But when nasty Jack Frost casts a spell on the fairies' special pets, farm animals everywhere start behaving very strangely! Can the girls help the Baby Farm Animal Fairies put things right?",
  },

  {
      "image": "Elsa 9781408342640.jpg",
      "name": "Elsa",
      "type": "the Mistletoe Fairy",
      "series": "Specials",
      "description": "Rachel and Kirsty are very excited to be spending Christmas together. But when Jack Frost steals Elsa the Mistletoe Fairy's magical objects, the festive season in both the human and fairy worlds is at risk! Can the girls help get Elsa's objects back before the magic of Christmas is ruined forever?",
  },

  {
      "image": "Elsie 9781408355183.jpg",
      "name": "Elsie",
      "type": "the Engineer Fairy",
      "series": "The Discovery Fairies",
      "description": "Rachel and Kirsty are so excited to meet the Discovery Fairies, who look after some of the most exciting jobs in the world. But when Jack Frost steals Elsie the Engineer Fairy's magical item, engineers everywhere lose their very special skills! Can the girls help Elsie defeat him and save the magic of engineering from being destroyed?",
  },

  {
      "image": "Emily 9781843629559.jpg",
      "name": "Emily",
      "type": "the Emerald Fairy",
      "series": "The Jewel Fairies",
      "description": "Disaster has struck Fairyland! Jack Frost has stolen all the jewels from Queen Titania's crown. Without them, the fairies' magic dust is losing its power. Rachel and Kirsty have to help the Jewel Fairies to get the jewels back... before it's too late.",
  },

  {
      "image": "Emma 9781408312803.jpg",
      "name": "Emma",
      "type": "the Easter Fairy",
      "series": "Specials",
      "description": "Emma the Easter Fairy works alongside the Easter Bunny to make sure that this special time of year is a happy and fun-filled time for everyone! But when the pesky goblins steal Emma's magical Easter Eggs, chaos reigns! Can Rachel and Kirsty help Emma save Easter before it's ruined forever...?",
  },

  {
      "image": "Erin 9781408303511.jpg",
      "name": "Erin",
      "type": "the Firebird Fairy",
      "series": "The Magical Animal Fairies",
      "description": "The Magical Animal Fairies look after seven young Magical animals, and train them to use their powers wisely, for the good of Fairyland and the human world. But the animals have been stolen!  In this third book in the series, Erin needs the girls' help to find her beloved Firebird, Giggles, before he is lost forever in the human world...",
  },

  {
      "image": "Esme 9781408324974.jpg",
      "name": "Esme",
      "type": "the Ice cream Fairy",
      "series": "The Sweet Fairies",
      "description": "Rachel and Kirsty are really excited - Kirsty's aunt works at Candy Land, and has brought them a big bag of sweets! But they all taste awful... Can the girls help the Sweet Fairies and stop Jack Frost so that sweets taste delicious again?",
  },

  {
      "image": "Esther 9781408342688.jpg",
      "name": "Esther",
      "type": "the Kindness Fairy",
      "series": "The Friendship Fairies",
      "description": "Everyone knows Rachel and Kirsty are best friends. But when Jack Frost steals the Friendship Fairies' magical objects, the special bond of BFFs everywhere is at risk! Can they fight the Ice Lord and his goblin minions to restore the magic of friendship in both the Fairyland and worlds?",
  },

  {
      "image": "Etta 9781408355008.jpg",
      "name": "Etta",
      "type": "the Elephant Fairy",
      "series": "The Endangered Animals Fairies",
      "description": "Rachel and Kirsty are going to a very special safari park! They are very excited to help the endangered animals who live there. But naughty Jack Frost wants to steal the animals for himself! Can the girls help the Endangered Animals Fairies stop him?",
  },

  {
      "image": "Eva 9781408312995.jpg",
      "name": "Eva",
      "type": "the Enchanted Ball Fairy",
      "series": "The Princess Fairies",
      "description": "Rachel and Kirsty have been invited to meet the seven nieces of Queen Titania and King Oberon - the Princess Fairies! But when Rachel and Kirsty arrive in Fairyland things soon go wrong, Jack Frost and his wicked goblins steal the princesses' tiaras! The tiaras contain special fairy magic that look after happiness and fun in both the human and fairy worlds. They need to get them back so everyone can be happy once again!",
  },

  {
      "image": "Evelyn 9781408357545.jpg",
      "name": "Evelyn",
      "type": "the Mermicorn Fairy",
      "series": "Specials",
      "description": "Evelyn the Mermicorn Fairy has a very special magical pet. But when Jack Frost steals him away from her, the magic of the mermicorn is in danger! Can Rachel and Kirsty help get him back?",
  },

  {
      "image": "Evie 9781843626367.jpg",
      "name": "Evie",
      "type": "the Mist Fairy",
      "series": "The Weather Fairies",
      "description": "Jack Frost has promised not to trouble the Rainbow Fairies again, but he didn’t say anything about the Weather Fairies! Now he has stolen the feathers from Doodle, the weather-vane cockerel in charge of the weather. It’s up to Rachel and Kirsty to get each of the feathers back!",
  },

  {
      "image": "Faith 9781408336762.jpg",
      "name": "Faith",
      "type": "the Cinderella Fairy",
      "series": "The Fairytale Fairies",
      "description": "Rachel and Kirsty are having a wonderful time at the Fairytale Festival. But Jack Frost has stolen Fairytale Fairies' magical objects, and now Fairytale characters are lost in the human world! Can Rachel and Kirsty help find them and keep fairytales everywhere magical?",
  },

  {
      "image": "Fatima 9781408349595.jpg",
      "name": "Fatima",
      "type": "the Face-Painting Fairy",
      "series": "The Fun Fair Fairies",
      "description": "Kirsty is so excited to visit Rachel for a weekend and even more excited to visit The Fernandos' Fabulous Funfair! But Jack Frost wants a funfair all to himself and he's stolen the Funfair Fairies magic keyring to make it happen. Can Rachel and Kirsty defeat him, before the funfair is totally wrecked!",
  },

  {
      "image": "Fern 9781843620198.jpg",
      "name": "Fern",
      "type": "the Green Fairy",
      "series": "The Rainbow Fairies",
      "description": "When Rachel and Kirsty meet on the ferry to Rainspell Island one summer holiday, they have no idea that such magical adventures with the fairies await them! Join Rachel and Kirsty as they search a secret garden and a magical maze to try to find Fern the Green Fairy.",
  },

  {
      "image": "Fiona 9781408300299.jpg",
      "name": "Fiona",
      "type": "the Flute Fairy",
      "series": "The Music Fairies",
      "description": "The Music Fairies desperately need Rachel's and Kirsty's help! Jack Frost and his naughty goblins have stolen the fairies' Magical Musical Instruments to help him win a national talent competition. If he wins, he'll reveal Fairyland to the human world - and put all of the Fairies in danger!",
  },

  {
      "image": "Fizz 9781408341001.jpg",
      "name": "Fizz",
      "type": "the Fireworks Fairy",
      "series": "Specials",
      "description": "Rachel and Kirsty are delighted to be going to the Sundown Village fireworks festival. But when Jack Frost steals Fizz the Fireworks Fairy's magical objects, it looks like the show will fizzle out before it's begun! Can Rachel and Kirsty help get them back and return the sparkle to Sundown?",
  },

  {
      "image": "Flora 9781846165054.jpg",
      "name": "Flora",
      "type": "the Fancy Dress Fairy",
      "series": "Specials",
      "description": "Rachel and Kirsty must help Flora the Fancy Dress Fairy to rescue her three magic items and make sure that Jack Frost and his naughty goblins don't ruin Fancy Dress parties forever!",
  },

  {
      "image": "Florence 9781408312384.jpg",
      "name": "Florence",
      "type": "the Friendship Fairy",
      "series": "Specials",
      "description": "Florence the Friendship Fairy has a very important job - she makes sure that friendships in Fairyland and the human world are looked after! But mean Jack Frost is jealous of everyone who has friends, as he's too grumpy to have any of his own. He steals Florence's three magical items (a memory book, a ribbon and a friendship bracelet) and hides them. Can Rachel and Kirsty find these items before their own friendship is threatened...?",
  },

  {
      "image": "Frances 9781408339381.jpg",
      "name": "Frances",
      "type": "the Royal Family Fairy",
      "series": "Specials",
      "description": "Frances the Royal Family Fairy makes sure all royal brothers and sisters get along well with each other. But when naughty Jack Frost steals her magical object, everything starts to fall apart! Can Rachel and Kirsty help Frances restore harmony in all royal families and their kingdoms?",
  },

  {
      "image": "Francesca 9781846168895.jpg",
      "name": "Francesca",
      "type": "the Football Fairy",
      "series": "The Sporty Fairies",
      "description": "Everyone in Fairyland is preparing for the Fairy Olympics, but Jack Frost and his goblins have stolen the magic sporty items so they can win by cheating! And with the items missing, the human Olympics will be ruined too...can Rachel and Kirsty get the items back before it's too late?",
  },

  {
      "image": "Frankie 9781408315934.jpg",
      "name": "Frankie",
      "type": "the Make-Up Fairy",
      "series": "The Pop Star Fairies",
      "description": "Rachel and Kirsty are in for a surprise when their fairy friends whisk them off to the Fairyland Music Festival, only for Jack Frost and his goblins to strut onto the stage! The naughty gang have stolen the Pop Star Fairies' magical notes, and Jack Frost intends to use them to become the biggest star in the world. They have to get them back - or concerts everywhere will be ruined!",
  },

  {
      "image": "Freya 9781846161926.jpg",
      "name": "Freya",
      "type": "the Friday Fairy",
      "series": "The Fun Day Fairies",
      "description": "Mean Jack Frost has stolen the seven Fairyland Fun Day Flags from the Fun Day Fairies! In this book, Rachel and Kirsty must make sure that the naughty goblins don't get their hands on Freya's flag, or Fridays will be ruined forever...",
  },

  {
      "image": "Gabby 9781408347232.jpg",
      "name": "Gabby",
      "type": "the Bubble Gum Fairy",
      "series": "The Candyland Fairies",
      "description": "Rachel and Kirsty are delighted to be invited to Fairyland for the annual Candy Harvest. But when the Candyland Fairies' magical objects go missing, they find themselves in a sticky situation! Can the girls defeat Jack Frost in time to save the Fairyland Sweet Factory from a bitter end?",
  },

  {
      "image": "Gabriella 9781408300343.jpg",
      "name": "Gabriella",
      "type": "the Snow Kingdom Fairy",
      "series": "Specials",
      "description": "Gabriella the Snow Kingdom Fairy makes Christmas a sparkly, cozy and happy time with her three magical objects - the Magic Snowflake, the Magic Firestone and the Festive Spirit. But Jack Frost and his goblins have stolen them! Can Rachel and Kirsty help Gabriella find the objects before Christmas is ruined for everyone...?",
  },

  {
      "image": "Gemma 9781846168949.jpg",
      "name": "Gemma",
      "type": "the Gymnastics Fairy",
      "series": "The Sporty Fairies",
      "description": "Everyone in Fairyland is preparing for the Fairy Olympics, but Jack Frost and his goblins have stolen the magic sporty items so they can win by cheating! And with the items missing, the human Olympics will be ruined too...can Rachel and Kirsty get the items back before it's too late?",
  },

  {
      "image": "Georgia 9781846161681.jpg",
      "name": "Georgia",
      "type": "the Guinea Pig Fairy",
      "series": "The Pet Keeper Fairies",
      "description": "Fairyland is in uproar! Jack Frost has stolen the Pet Fairies magical pets. Without them, the Pet Fairies can't ensure that pets in the human world find the right owners. And now the pets have escaped from Jack Frost's ice castle and are roaming the human world! Rachel and Kirsty have to help get the pets back...before it's too late.",
  },

  {
      "image": "Georgie 9781408330647.jpg",
      "name": "Georgie",
      "type": "the Royal Prince Fairy",
      "series": "Specials",
      "description": "Rachel and Kirsty are very excited to attend a special royal weekend organized by the Queen! But when the Royal signet ring mysteriously disappears, the girls are in for another fantastic Rainbow Magic adventure! Help Rachel and Kirsty find the royal signet ring before it's too late!",
  },

  {
      "image": "Giselle 9781408333778.jpg",
      "name": "Giselle",
      "type": "the Christmas Ballet Fairy",
      "series": "Specials",
      "description": "Every year, Giselle the Christmas Ballet Fairy's special magic helps every ballet show run perfectly. But when naughty Jack Frost turns up and steals her magical objects, it looks like everything will be ruined! Can Rachel and Kirsty get them back in time for the Fairyland Christmas Eve performance? There's a drinking bottle, a ballet bag and a pair of shoes in this book. Read on to see if Rachel, Kirsty and Giselle can find them!",
  },

  {
      "image": "Goldie 9781843626411.jpg",
      "name": "Goldie",
      "type": "the Sunshine Fairy",
      "series": "The Weather Fairies",
      "description": "Jack Frost has promised not to trouble the Rainbow Fairies again, but he didn’t say anything about the Weather Fairies! Now he has stolen the feathers from Doodle, the weather-vane cockerel in charge of the weather. It’s up to Rachel and Kirsty to get each of the feathers back!",
  },

  {
      "image": "Grace 9781843628200.jpg",
      "name": "Grace",
      "type": "the Glitter Fairy",
      "series": "The Party Fairies",
      "description": "It's Queen Titania's and King Oberon's 1000th jubilee, and the seven Party Fairies are in charge of making the celebratory party extra-special! However, naughty Jack Frost has stolen each of the seven Party Fairies' magical party bags... if he succeeds the Fairyland celebrations will be ruined! Can Rachel and Kirsty help rescue the party bags so the magnificent jubilee cake can be finished in time...?",
  },

  {
      "image": "Greta 9781408362426.jpg",
      "name": "Greta",
      "type": "the Earth Fairy",
      "series": "Specials",
      "description": "Greta the Earth Fairy's special magic helps fairies and humans everywhere care about the environment. But when Jack Frost steals her magical objects, everyone forgets to recycle, uses too much plastic and starts having really long showers! Can Rachel, Kirsty and Greta get the magical objects back and teach the world about how best to save our planet?",
  },

  {
      "image": "Hana the Hanukkah Fairy.jpeg",
      "name": "Hana",
      "type": "the Hanukkah fairy",
      "series": "The Festival Fairies",
      "description": "When Hana the Hanukkah Fairy's hanukkiah is stolen by naughty Jack Frost, Hanukkah celebrations everywhere are at risk. Rachel and Kirsty must help Hana find her magical object!",
  },

  {
      "image": "Hannah 9781846162527.jpg",
      "name": "Hannah",
      "type": "the Happy Ever After Fairy",
      "series": "Specials",
      "description": "Join Hannah the Happy Ever After Fairy for an exciting fairy adventure! Hannah the Happy Ever After Fairy is very upset. Jack Frost has stolen her Magic Quill Pen! Can Rachel and Kirsty help Hannah find it and save the Happy Ever Afters...?",
  },

  {
      "image": "Harriet 9781846161674.jpg",
      "name": "Harriet",
      "type": "the Hamster Fairy",
      "series": "The Pet Keeper Fairies",
      "description": "Fairyland is in uproar! Jack Frost has stolen the Pet Fairies magical pets. Without them, the Pet Fairies can't ensure that pets in the human world find the right owners. And now the pets have escaped from Jack Frost's ice castle and are roaming the human world! Rachel and Kirsty have to help get the pets back...before it's too late.",
  },

  {
      "image": "Hayley 9781843626381.jpg",
      "name": "Hayley",
      "type": "the Rain Fairy",
      "series": "The Weather Fairies",
      "description": "Jack Frost has promised not to trouble the Rainbow Fairies again, but he didn’t say anything about the Weather Fairies! Now he has stolen the feathers from Doodle, the weather-vane cockerel in charge of the weather. It’s up to Rachel and Kirsty to get each of the feathers back!",
  },

  {
      "image": "Heather 9781843620228.jpg",
      "name": "Heather",
      "type": "the Violet Fairy",
      "series": "The Rainbow Fairies",
      "description": "When Rachel and Kirsty meet on the ferry to Rainspell Island one summer holiday, they have no idea that such magical adventures with the fairies await them! In the last exciting book in the series, the girls must find Heather the Violet Fairy and then go with the fairies to Fairyland to make sure that all the colours have returned to the magical kingdom!",
  },

  {
      "image": "Heidi 9781408336472.jpg",
      "name": "Heidi",
      "type": "the Vet Fairy",
      "series": "Specials",
      "description": "Rachel and Kirsty are very excited to be helping out at a new veterinary surgery! They love looking after all the cute animals. But strange things start happening, and soon it seems everything is going wrong. . .could this be the work of nasty Jack Frost? The girls must help Heidi the Vet Fairy defeat him and save animals everywhere!",
  },

  {
      "image": "Helena 9781846168888.jpg",
      "name": "Helena",
      "type": "the Horseriding Fairy",
      "series": "The Sporty Fairies",
      "description": "The Sporty Fairies are in need of Rachel and Kirsty's help. Everyone in Fairyland is preparing for the Fairy Olympics, but Jack Frost and his goblins have stolen the magic sporty items so they can win by cheating! What's more, with the items missing, everyone in the human world is playing sports really badly, and so the human Olympics is in danger of being ruined too! Can Helena the Horseriding Fairy get her Magic Hard Hat back before a goblin gallops off with it?",
  },

  {
      "image": "Holly 9781843626619.jpg",
      "name": "Holly",
      "type": "the Christmas Fairy",
      "series": "Specials",
      "description": "The King and Queen of Fairyland are all in a fluster. Jack Frost is up to his old tricks and has kidnapped the Christmas elves. Without the elves to make the presents and decorate the trees, there won't be any Christmas this year. Can Rachel and Kirsty save the day, with a little help from Holly the Christmas Fairy?",
  },

  {
      "image": "Honey 9781843628217.jpg",
      "name": "Honey",
      "type": "the Sweet Fairy",
      "series": "The Party Fairies",
      "description": "It's Queen Titania's and King Oberon's 1000th jubilee, and the seven Party Fairies are in charge of making the celebratory party extra-special! However, naughty Jack Frost has stolen each of the seven Party Fairies' magical party bags... if he succeeds the Fairyland celebrations will be ruined! Can Rachel and Kirsty help rescue the party bags so the magnificent jubilee cake can be finished in time...?",
  },

  {
      "image": "Honor 9781408312933.jpg",
      "name": "Honor",
      "type": "the Happy Days Fairy",
      "series": "The Princess Fairies",
      "description": "Rachel and Kirsty have been invited to meet the seven nieces of Queen Titania and King Oberon - the Princess Fairies! But when Rachel and Kirsty arrive in Fairyland things soon go wrong, Jack Frost and his wicked goblins steal the princesses' tiaras! The tiaras contain special fairy magic that look after happiness and fun in both the human and fairy worlds. They need to get them back so everyone can be happy once again!",
  },

  {
      "image": "Imogen 9781846164972.jpg",
      "name": "Imogen",
      "type": "the Ice Dance Fairy",
      "series": "The Dance Fairies",
      "description": "Jack Frost's naughty goblins have stolen the Dance Fairies' magic ribbons and are hiding in the human world. Everywhere dance is being ruined! Rachel and Kirsty must help the fairies but the goblins' new powers won't make it easy...",
  },

  {
      "image": "India 9781843629580.jpg",
      "name": "India",
      "type": "the Moonstone Fairy",
      "series": "The Jewel Fairies",
      "description": "Disaster has struck Fairyland! Jack Frost has stolen all the jewels from Queen Titania's crown. Without them, the fairies' magic dust is losing its power. Rachel and Kirsty have to help the Jewel Fairies to get the jewels back... before it's too late.",
  },

  {
      "image": "Isabella 9781408304754.jpg",
      "name": "Isabella",
      "type": "the Air Fairy",
      "series": "The Green Fairies",
      "description": "Rachel and Kirsty are excited for their holiday - but their hearts sink when they see their favourite beach is covered in litter. They visit the King and Queen of Fairyland , who introduce them to seven trainee fairies, the Green Fairies. But then Jack Frost turns up to steal their new wands! Rachel and Kirsty will have to get them back if they want the world to be clean...",
  },

  {
      "image": "Isla 9781408312926.jpg",
      "name": "Isla",
      "type": "the Ice Star Fairy",
      "series": "The Showtime Fairies",
      "description": "Jack Frost has stolen the Showtime Fairies' magic stars, which means everyone is losing their special talent! Can Rachel and Kirsty get the stars back before the Tippington Variety Show is ruined?",
  },

  {
      "image": "Ivy 9781408355107.jpg",
      "name": "Ivy",
      "type": "the Worry Fairy",
      "series": "Specials",
      "description": "Ivy the Worry Fairy has a very important job - helping people manage their worries. Everyone gets worried from time to time, and it's important to know how to deal with it. But when Jack Frost steals Ivy's magical objects, her special power to help people is threatened! Can Rachel and Kirsty help get them back and help restore people's inner calm everywhere?",
  },

  {
      "image": "Izzy 9781843620211.jpg",
      "name": "Izzy",
      "type": "the Indigo Fairy",
      "series": "The Rainbow Fairies",
      "description": "When Rachel and Kirsty meet on the ferry to Rainspell Island one summer holiday, they have no idea that such magical adventures with the fairies await them! In this sixth book in the series, Rachel and Kirsty enter a magical world in order to find Izzy the Indigo Fairy...",
  },

  {
      "image": "Jacinda 9781408362969.jpg",
      "name": "Jacinda",
      "type": "the Peace Fairy",
      "series": "Specials",
      "description": "Jacinda the Peace Fairy helps to maintain peace and harmony in Fairyland and the real world. But when her special magnifying glass goes missing, people lose the ability to see things from each other's point of view, and everyone starts to argue. Can Rachel, Kirsty and Jacinda find the missing magical object and restore peace to the world?",
  },

  {
      "image": "Jade 9781846164910.jpg",
      "name": "Jade",
      "type": "the Disco Fairy",
      "series": "The Dance Fairies",
      "description": "Jack Frost's naughty goblins have stolen the Dance Fairies' magic ribbons and are hiding in the human world. Everywhere dance is being ruined! Rachel and Kirsty must help the fairies but the goblins' new powers won't make it easy...",
  },

  {
      "image": "Jae 9781408360316.jpg",
      "name": "Jae",
      "type": "the Boy Band Fairy",
      "series": "Specials",
      "description": "Rachel and Kirsty are so excited to be going to see their favourite boy band in concert! But when Jack Frost steals Jae the Boy Band Fairy's magical microphone, everyone gets stage fright and forgets their moves. Can Rachel, Kirsty and Jae help the show go off without a hitch?",
  },

  {
      "image": "Jasmine 9781843628248.jpg",
      "name": "Jasmine",
      "type": "the Present Fairy",
      "series": "The Party Fairies",
      "description": "It's Queen Titania's and King Oberon's 1000th jubilee, and the seven Party Fairies are in charge of making the celebratory party extra-special! However, naughty Jack Frost has stolen each of the seven Party Fairies' magical party bags... if he succeeds the Fairyland celebrations will be ruined! Can Rachel and Kirsty help rescue the party bags so the magnificent jubilee cake can be finished in time...?",
  },

  {
      "image": "Jennifer 9781408325100.jpg",
      "name": "Jennifer",
      "type": "the Babysitter Fairy",
      "series": "Specials",
      "description": "Jennifer the Babysitter Fairy keeps all babies safe and happy. That is, until her three magical objects disappear! With Jennifer's precious items missing, all fairy and human children are naughty and upset. Can Rachel and Kirsty help Jennifer make all babies happy again? Join Rachel and Kirsty, and see if Jennifer finds her three magical objects: a toy box, a snack pack and a nightlight!",
  },

  {
      "image": "Jessica 9781846164958.jpg",
      "name": "Jessica",
      "type": "the Jazz Fairy",
      "series": "The Dance Fairies",
      "description": "Jack Frost's naughty goblins have stolen the Dance Fairies' magic ribbons and are hiding in the human world. Everywhere dance is being ruined! Rachel and Kirsty must help the fairies but the goblins' new powers won't make it easy...",
  },

  {
      "image": "Jessie 9781408315897.jpg",
      "name": "Jessie",
      "type": "the Lyrics Fairy",
      "series": "The Pop Star Fairies",
      "description": "Rachel and Kirsty are in for a surprise when their fairy friends whisk them off to the Fairyland Music Festival, only for Jack Frost and his goblins to strut onto the stage! The naughty gang have stolen the Pop Star Fairies' magical notes, and Jack Frost intends to use them to become the biggest star in the world. They have to get them back - or concerts everywhere will be ruined!",
  },

  {
      "image": "Josie 9781408331477.jpg",
      "name": "Josie",
      "type": "the Jewellery-Making Fairy",
      "series": "The Magical Craft Fairies",
      "description": "Rachel and Kirsty return to Rainspell Island for Crafts Week. They’re so excited try all of the workshops. But after the Magical Crafts Fairies’ items are stolen, all types of crafts are in chaos. Jewellery will keep breaking, artists won’t be able to draw properly and paint will come out grey! Can they help the Magical Crafts Fairies get them back before Crafts Week is ruined?",
  },

  {
      "image": "Jude 9781408357729.jpg",
      "name": "Jude",
      "type": "the Librarian Fairy",
      "series": "Specials",
      "description": "Jude the Librarian Fairy looks after readers in both the fairy and human worlds. But when Jack Frost steals her lucky library stamp, radiant reading glasses and brilliant bookmark, it spells trouble for libraries everywhere! Can Rachel and Kirsty help defeat him, before the magic of reading is ruined?",
  },

  {
      "image": "Julia 9781408336489.jpg",
      "name": "Julia",
      "type": "the Sleeping Beauty Fairy",
      "series": "The Fairytale Fairies",
      "description": "Rachel and Kirsty are having a wonderful time at the Fairytale Festival. But Jack Frost has stolen Fairytale Fairies' magical objects, and now Fairytale characters are lost in the human world! Can Rachel and Kirsty help find them and keep fairytales everywhere magical?",
  },

  {
      "image": "Juliet 9781408311356.jpg",
      "name": "Juliet",
      "type": "the Valentine Fairy",
      "series": "Specials",
      "description": "Juliet the Valentine Fairy is in a terrible state! She helps make every Valentines Day special for friends and sweethearts everywhere. But Jack Frost and his naughty goblins have stolen her three special Valentine objects, so nobody is enjoying this most special and wonderful of days! Can Rachel and Kirsty help Juliet, or will the missing Valentine items affect their friendship, too...?",
  },

  {
      "image": "Kat 9781408345054.jpg",
      "name": "Kat",
      "type": "the Jungle Fairy",
      "series": "Specials",
      "description": "Kat the Jungle Fairy looks after tropical jungles in the fairy and human worlds. But when Jack Frost and his goblins steal her magical objects, jungles everywhere are in danger! Can Rachel and Kirsty help get them back and save all the tropical plants and animals?",
  },

  {
      "image": "Kate 9781408315248.jpg",
      "name": "Kate",
      "type": "the Royal Wedding Fairy",
      "series": "Specials",
      "description": "Kate the Royal Wedding Fairy makes sure that all weddings are happy and magical! But when mean Jack Frost steals the True Love Crown, the Fairyland royal wedding is sure to be a disaster. Can Rachel and Kirsty find the crown so the royal couple will live happily ever after...?",
  },

  {
      "image": "Kathryn 9781408333945.jpg",
      "name": "Kathryn",
      "type": "the PE Fairy",
      "series": "The School Days Fairies",
      "description": "Rachel and Kirsty can’t wait to go to school together for a week! But trouble starts when Jack Frost steals the School Days Fairies magical star badges. Can the girls get it back before everyone’s lessons go horribly wrong?",
  },

  {
      "image": "Katie 9781408335598.jpg",
      "name": "Katie",
      "type": "the Kitten Fairy",
      "series": "The Pet Keeper Fairies",
      "description": "Fairyland is in uproar! Jack Frost has stolen the Pet Fairies magical pets. Without them, the Pet Fairies can't ensure that pets in the human world find the right owners. And now the pets have escaped from Jack Frost's ice castle and are roaming the human world! Rachel and Kirsty have to help get the pets back...before it's too late.",
  },

  {
      "image": "Kayla 9781408331415.jpg",
      "name": "Kayla",
      "type": "the Pottery Fairy",
      "series": "The Magical Craft Fairies",
      "description": "Rachel and Kirsty return to Rainspell Island for Crafts Week. They’re so excited try all of the workshops. But after the Magical Crafts Fairies’ items are stolen, all types of crafts are in chaos. Jewellery will keep breaking, artists won’t be able to draw properly and paint will come out grey! Can they help the Magical Crafts Fairies get them back before Crafts Week is ruined?",
  },

  {
      "image": "Keira 9781408315972.jpg",
      "name": "Keira",
      "type": "the Film Star Fairy",
      "series": "Specials",
      "description": "A real Hollywood blockbuster is filming in Kirsty's village, and the girls have parts as extras! Everything goes like a dream, until Jack Frost's goblins steal Keira the Film Star Fairy's three magical items. Unless Rachel and Kirsty can get the Silver Script, the Magical Megaphone and the Enchanted Clapperboard back, work on a movie set will never go right again.",
  },

  {
      "image": "Kimberly 9781408327975.jpg",
      "name": "Kimberly",
      "type": "the Koala Fairy",
      "series": "The Baby Animal Rescue Fairies",
      "description": "Kirsty Tate and Rachel Walker are helping out at the Wild Woods Nature Reserve. They’re having a great time meeting cute animals and making friends with The Baby Animal Rescue Fairies. But when Jack Frost decides he wants baby animals for his own personal zoo, he steals the fairies’ magical key rings. Can the girls help the fairies get their key rings back before the baby animals are trapped inside the Ice Lord’s zoo for ever?",
  },

  {
      "image": "Kitty 9781408327944.jpg",
      "name": "Kitty",
      "type": "the Tiger Fairy",
      "series": "The Baby Animal Rescue Fairies",
      "description": "Kirsty Tate and Rachel Walker are having a fantastic time helping out at the Wild Woods Nature Reserve. But nasty Jack Frost has stolen The Baby Animal Rescue Fairies' magical key rings, so he can have his own personal zoo. His latest target is cute and cuddly tiger cub, Amber. The girls must help Kitty the Tiger Fairy find her key ring and rescue little Amber - before Jack Frost captures all human and fairy wildlife!",
  },

  {
      "image": "Konnie 9781408356296.jpg",
      "name": "Konnie",
      "type": "the Christmas Cracker Fairy",
      "series": "Specials",
      "description": "Rachel and Kirsty are very excited to be spending the Christmas holidays together. But when Jack Frost steals Konnie the Christmas Cracker Fairy's magical objects, festive cheer is at risk! Can the girls help get Konnie's objects back before the magic of Christmas is ruined forever?",
  },

  {
      "image": "Kylie 9781408322208.jpg",
      "name": "Kylie",
      "type": "the Carnival Fairy",
      "series": "Specials",
      "description": "Kylie, the Carnival Fairy has got her work cut out. Jack Frost and his goblins have stolen the three magic batons that control the Rainspell Island Summer Carnival. He's planning on spoiling the grand parade, the ferris wheel and the firework finale. Kylie, the Carnival Fairy needs Rachel and Kirsty's help to get the batons back and bring all the fun to the fair...",
  },

  {
      "image": "Lacey 9781408336786.jpg",
      "name": "Lacey",
      "type": "the Little Mermaid Fairy",
      "series": "The Fairytale Fairies",
      "description": "Rachel and Kirsty are having a wonderful time at the Fairytale Festival. But Jack Frost has stolen Fairytale Fairies' magical objects, and now Fairytale characters are lost in the human world! Can Rachel and Kirsty help find them and keep fairytales everywhere magical?",
  },

  {
      "image": "Lara 9781408303504.jpg",
      "name": "Lara",
      "type": "the Black Cat Fairy",
      "series": "The Magical Animal Fairies",
      "description": "The Magical Animal Fairies look after seven young Magical Animals, and train them to use their powers wisely, for the good of Fairyland and the human world. But the animals have been stolen! Can Rachel and Kirsty help the Magical Animals before their powers cause chaos?",
  },

  {
      "image": "Lauren 9781846161698.jpg",
      "name": "Lauren",
      "type": "the Puppy Fairy",
      "series": "The Pet Keeper Fairies",
      "description": "Fairyland is in uproar! Jack Frost has stolen the Pet Fairies magical pets. Without them, the Pet Fairies can't ensure that pets in the human world find the right owners. And now the pets have escaped from Jack Frost's ice castle and are roaming the human world! Rachel and Kirsty have to help get the pets back...before it's too late.",
  },

  {
      "image": "Layla 9781408325018.jpg",
      "name": "Layla",
      "type": "the Candyfloss Fairy",
      "series": "The Sweet Fairies",
      "description": "Rachel and Kirsty are really excited - Kirsty's aunt works at Candy Land, and has brought them a big bag of sweets! But they all taste awful... Can the girls help the Sweet Fairies and stop Jack Frost so that sweets taste delicious again?",
  },

  {
      "image": "Leah 9781408312872.jpg",
      "name": "Leah",
      "type": "the Theatre Fairy",
      "series": "The Showtime Fairies",
      "description": "Jack Frost has stolen the Showtime Fairies' magic stars, which means everyone is losing their special talent! Can Rachel and Kirsty get the stars back before the Tippington Variety Show is ruined?",
  },

  {
      "image": "Leona 9781408303542.jpg",
      "name": "Leona",
      "type": "the Unicorn Fairy",
      "series": "The Magical Animal Fairies",
      "description": "The Magical Animal Fairies look after seven young Magical Animals, and train them to use their powers wisely, for the good of Fairyland and the human world. But the animals have been stolen! Can Rachel and Kirsty help the Magical Animals before their powers cause chaos?",
  },

  {
      "image": "Lexi 9781408309070.jpg",
      "name": "Lexi",
      "type": "the Firefly Fairy",
      "series": "The Twilight Fairies",
      "description": "Mean Jack Frost has kidnapped all the fireflies, leaving Fairyland in darkness! Rachel and Kirsty must help Lexi the Firefly Fairy find her magical bag of fire dust. But first the fireflies need rescuing...",
  },

  {
      "image": "Libby 9781408331514.jpg",
      "name": "Libby",
      "type": "the Story-Writing Fairy",
      "series": "The Magical Craft Fairies",
      "description": "Rachel and Kirsty return to Rainspell Island for Crafts Week. They’re so excited try all of the workshops. But after the Magical Crafts Fairies’ items are stolen, all types of crafts are in chaos. Jewellery will keep breaking, artists won’t be able to draw properly and paint will come out grey! Can they help the Magical Crafts Fairies get them back before Crafts Week is ruined?",
  },

  {
      "image": "Lila Myla 9781408330678.jpg",
      "name": "Lila and Myla",
      "type": "the Twins Fairies",
      "series": "Specials",
      "description": "Rachel and Kirsty are invited to a special birthday celebration organised for twins! At the party, the two friends meet Lila and Myla, the Twins Fairies, but Jack Frost has stolen their magical items! Now the girls must help Lila and Myla get them back, before Jack Frost creates his very own twin! Can the girls help their fairy friends  get their magical items back before it's too late? There's a heart necklace and a Gemini ring to find in this book.",
  },

  {
      "image": "Lily 9781408304785.jpg",
      "name": "Lily",
      "type": "the Rainforest Fairy",
      "series": "The Green Fairies",
      "description": "When Lily the Rainforest Fairy whisks Rachel and Kirsty away to a tropical forest, they expect to meet lots of exotic creatures..not a group of rowdy goblins! The girls must show them how to behave responsibly in the rainforest, and how to look after it. But will they listen...?",
  },

  {
      "image": "Lisa 9781408347256.jpg",
      "name": "Lisa",
      "type": "the Jelly Bean Fairy",
      "series": "The Candyland Fairies",
      "description": "Rachel and Kirsty are delighted to be invited to Fairyland for the annual Candy Harvest. But when the Candyland Fairies' magical objects go missing, they find themselves in a sticky situation! Can the girls defeat Jack Frost in time to save the Fairyland Sweet Factory from a bitter end?",
  },

  {
      "image": "Lizzie 9781408312971.jpg",
      "name": "Lizzie",
      "type": "the Sweet Treats Fairy",
      "series": "The Princess Fairies",
      "description": "Rachel and Kirsty have been invited to meet the seven nieces of Queen Titania and King Oberon - the Princess Fairies! But when Rachel and Kirsty arrive in Fairyland things soon go wrong, Jack Frost and his wicked goblins steal the princesses' tiaras! The tiaras contain special fairy magic that look after happiness and fun in both the human and fairy worlds. They need to get them back so everyone can be happy once again!",
  },

  {
      "image": "Lola 9781408316801.jpg",
      "name": "Lola",
      "type": "the Fashion Show Fairy",
      "series": "The Fashion Fairies",
      "description": "Rachel and Kirsty are excited to be entering a charity fashion show where children get to show off their own designs. Unfortunately, Jack Frost thinks everyone in the world should dress like him, and steals the Fashion Fairies' seven magical items to put his naughty plan into action!",
  },

  {
      "image": "Lottie 9781408324967.jpg",
      "name": "Lottie",
      "type": "the Lollipop Fairy",
      "series": "The Sweet Fairies",
      "description": "Rachel and Kirsty are really excited - Kirsty's aunt works at Candy Land, and has brought them a big bag of sweets! But they all taste awful... Can the girls help the Sweet Fairies and stop Jack Frost so that sweets taste delicious again?",
  },

  {
      "image": "Louise 9781846164590.jpg",
      "name": "Louise",
      "type": "the Lily Fairy",
      "series": "The Petal Fairies",
      "description": "The Petal Fairies have a very important job - together with their magic petals they help to make sure that flowers grow and bloom in the human world. But after Jack Frost and his goblins steal - and lose - the magic petals Rachel and Kirsty must help find them before the goblins get their hands on them!",
  },

  {
      "image": "Lucy 9781843629597.jpg",
      "name": "Lucy",
      "type": "the Diamond Fairy",
      "series": "The Jewel Fairies",
      "description": "Disaster has struck Fairyland! Jack Frost has stolen all the jewels from Queen Titania's crown. Without them, the fairies' magic dust is losing its power. Rachel and Kirsty have to help the Jewel Fairies to get the jewels back... before it's too late.",
  },

  {
      "image": "Lulu 9781408339497.jpg",
      "name": "Lulu",
      "type": "the Lifeguard Fairy",
      "series": "The Helping Fairies",
      "description": "It's time for the Tippington Helper Award to be given and Rachel and Kirsty are excited to meet the nominees. Jack Frost has decided the winner, though, will be him! He's stolen all of the Helping Fairies' magical items - can Rachel and Kirsty get them back before the Tippington Helper Award is given to least helpful Ice Lord there ever was?",
  },

  {
      "image": "Luna 9781408338988.jpg",
      "name": "Luna",
      "type": "the Loom Band Fairy",
      "series": "Specials",
      "description": "Luna the Loom Band Fairy makes sure everyone has lots of fun creating fabulous loom band arts and crafts. But when naughty Jack Frost steals her magical sparkling golden loom, all the creations start to go wrong! Can Rachel and Kirsty get it back and help save the day for loom band fans everywhere?",
  },

  {
      "image": "Lydia 9781408333976.jpg",
      "name": "Lydia",
      "type": "the Reading Fairy",
      "series": "The School Days Fairies",
      "description": "Rachel and Kirsty can’t wait to go to school together for a week! But trouble starts when Jack Frost steals the School Days Fairies magical star badges. Can the girls get it back before everyone’s lessons go horribly wrong?",
  },

  {
      "image": "Maddie 9781408312988.jpg",
      "name": "Maddie",
      "type": "the Playtime Fairy",
      "series": "The Princess Fairies",
      "description": "Rachel and Kirsty have been invited to meet the seven nieces of Queen Titania and King Oberon - the Princess Fairies! But when Rachel and Kirsty arrive in Fairyland things soon go wrong, Jack Frost and his wicked goblins steal the princesses' tiaras! The tiaras contain special fairy magic that look after happiness and fun in both the human and fairy worlds. They need to get them back so everyone can be happy once again!",
  },

  {
      "image": "Madeleine 9781408325001.jpg",
      "name": "Madeleine",
      "type": "the Cookie Fairy",
      "series": "The Sweet Fairies",
      "description": "Rachel and Kirsty are really excited - Kirsty's aunt works at Candy Land, and has brought them a big bag of sweets! But they all taste awful... Can the girls help the Sweet Fairies and stop Jack Frost so that sweets taste delicious again?",
  },

  {
      "image": "Madison 9781408312865.jpg",
      "name": "Madison",
      "type": "the Magic Show Fairy",
      "series": "The Showtime Fairies",
      "description": "Jack Frost has stolen the Showtime Fairies' magic stars, which means everyone is losing their special talent! Can Rachel and Kirsty get the stars back before the Tippington Variety Show is ruined?",
  },

  {
      "image": "Mae 9781408327937.jpg",
      "name": "Mae",
      "type": "the Panda Fairy",
      "series": "The Baby Animal Rescue Fairies",
      "description": "Kirsty Tate and Rachel Walker are helping out at the Wild Woods Nature Reserve. They’re having a great time meeting cute animals and making friends with The Baby Animal Rescue Fairies. But when Jack Frost decides he wants baby animals for his own personal zoo, he steals the fairies’ magical key rings. Can the girls help the fairies get their key rings back before the baby animals are trapped inside the Ice Lord’s zoo for ever?",
  },

  {
      "image": "Maisie 9781408309117.jpg",
      "name": "Maisie",
      "type": "the Moonbeam Fairy",
      "series": "The Twilight Fairies",
      "description": "Rachel and Kirsty are thrilled to be at Camp Stargaze. But Jack Frost has stolen the Twilight Fairies' magical bags, causing night-time chaos! Can the girls find all the bags of dust and restore the night to the way it's supposed to be?",
  },

  {
      "image": "Mara 9781408327951.jpg",
      "name": "Mara",
      "type": "the Meerkat Fairy",
      "series": "The Baby Animal Rescue Fairies",
      "description": "Kirsty Tate and Rachel Walker are helping out at the Wild Woods Nature Reserve. They’re having a great time meeting cute animals and making friends with The Baby Animal Rescue Fairies. But when Jack Frost decides he wants baby animals for his own personal zoo, he steals the fairies’ magical key rings. Can the girls help the fairies get their key rings back before the baby animals are trapped inside the Ice Lord’s zoo for ever?",
  },

  {
      "image": "Maria 9781408349656.jpg",
      "name": "Maria",
      "type": "the Mother's Day Fairy",
      "series": "Specials",
      "description": "Maria the Mother's Day Fairy looks after mums everywhere and makes sure they get on well with their sons and daughters. But when Jack Frost steals her magical objects, the magic of motherhood goes all wonky! Can Rachel and Kirsty help restore order in time for the special surprise they have planned for their mums?",
  },

  {
      "image": "Mariana 9781408340547.jpg",
      "name": "Mariana",
      "type": "the Goldilocks Fairy",
      "series": "The Storybook Fairies",
      "description": "Rachel and Kirsty are very excited to be going to the Wetherbury Storytelling Festival! But when mean Jack Frost steals the Storybook Fairies' magical objects, all the stories start getting mixed up. Can the girls help their fairy friends put things right?",
  },

  {
      "image": "Marissa 9781408333914.jpg",
      "name": "Marissa",
      "type": "the Science Fairy",
      "series": "The School Days Fairies",
      "description": "Rachel and Kirsty can’t wait to go to school together for a week! But trouble starts when Jack Frost steals the School Days Fairies magical star badges. Can the girls get it back before everyone’s lessons go horribly wrong?",
  },

  {
      "image": "Martha 9781408339435.jpg",
      "name": "Martha",
      "type": "the Doctor Fairy",
      "series": "The Helping Fairies",
      "description": "It's time for the Tippington Helper Award to be given and Rachel and Kirsty are excited to meet the nominees. Jack Frost has decided the winner, though, will be him! He's stolen all of the Helping Fairies' magical items - can Rachel and Kirsty get them back before the Tippington Helper Award is given to least helpful Ice Lord there ever was?",
  },

  {
      "image": "Mary 9781408342749.jpg",
      "name": "Mary",
      "type": "the Sharing Fairy",
      "series": "The Friendship Fairies",
      "description": "Everyone knows Rachel and Kirsty are best friends. But when Jack Frost steals the Friendship Fairies' magical objects, the special bond of BFFs everywhere is at risk! Can they fight the Ice Lord and his goblin minions to restore the magic of friendship in both the Fairyland and worlds?",
  },

  {
      "image": "Matilda 9781408316788.jpg",
      "name": "Matilda",
      "type": "the Hairstylist Fairy",
      "series": "The Fashion Fairies",
      "description": "Rachel and Kirsty are excited to be entering a charity fashion show where children get to show off their own designs. Unfortunately, Jack Frost thinks everyone in the world should dress like him, and steals the Fashion Fairies' seven magical items to put his naughty plan into action! Rachel and Kirsty must help the Fashion Fairies get them back, so that people everywhere can continue to look good and feel great.",
  },

  {
      "image": "Maya 9781408300312.jpg",
      "name": "Maya",
      "type": "the Harp Fairy",
      "series": "The Music Fairies",
      "description": "The Music Fairies desperately need Rachel's and Kirsty's help! Jack Frost and his naughty goblins have stolen the fairies' Magical Musical Instruments, which means that music is being ruined for everyone! Jack Frost plans to use the instruments to help him win a national talent competition in the human world, and with the help of the enchanted instruments, he's bound to win. If this happens, humans will find out about Fairyland and then all the fairies will be in danger!",
  },

  {
      "image": "Megan_9781408335666.jpg",
      "name": "Megan",
      "type": "the Monday Fairy",
      "series": "The Fun Day Fairies",
      "description": "Naughty Jack Frost has stolen the seven Fairyland Fun Day Flags, and the Fun Day fairies need Rachel and Kirsty's help to find them! In this book, Megan the Monday Fairy needs her flag to put fun into Mondays.",
  },

  {
      "image": "Meghan_9781408356647.jpg",
      "name": "Meghan",
      "type": "the Wedding Sparkle Fairy",
      "series": "Specials",
      "description": "Meghan's special magic looks after the magic of matrimony everywhere. But when naughty Jack Frost steals her magical triple-jewelled engagement ring (that she wears on a charm round her neck), he breaks the spell of love in both the fairy and human worlds! Couples and best friends everywhere keep falling out, and forgetting what it is they love about each other. Rachel and Kirsty must help Meghan get her ring back before the Fairyland royal wedding, and weddings everywhere, are ruined!",
  },

  {
      "image": "Melissa_9781408341643.jpg",
      "name": "Melissa",
      "type": "the Sports Fairy",
      "series": "Specials",
      "description": "Rachel and Kirsty are very excited to be going to Rainspell Island for a special sporting event. But when Jack Frost steals Melissa the Sports Fairy's magical objects, both the Rainspell and Fairyland Games are in jeopardy! Can the girls help Melissa get the objects back before the competitions are ruined in both the fairy and human worlds?",
  },

  {
      "image": "Melodie_9781843628194.jpg",
      "name": "Melodie",
      "type": "the Music Fairy",
      "series": "The Party Fairies",
      "description": "It's Queen Titania's and King Oberon's 1000th jubilee, and the seven Party Fairies are in charge of making the celebratory party extra-special! However, naughty Jack Frost has stolen each of the seven Party Fairies' magical party bags... if he succeeds the Fairyland celebrations will be ruined! Can Rachel and Kirsty help rescue the party bags so the magnificent jubilee cake can be finished in time...?",
  },

  {
      "image": "Mia_9781408303481.jpg",
      "name": "Mia",
      "type": "the Bridesmaid Fairy",
      "series": "Specials",
      "description": "Mia the Bridesmaid Fairy helps make weddings wonderful with her three magical items. But when these are stolen by mean Jack Frost and his naughty goblins, weddings everywhere descend into chaos! Rachel and Kirsty are about to be bridesmaids for Kirsty's cousin, Esther, so the race is on to help Mia find her magical objects (a silver sixpence, some golden bells and the moonshine veil) so everyone can enjoy a really special day.",
  },

  {
      "image": "Miley_9781408315927.jpg",
      "name": "Miley",
      "type": "the Stylist Fairy",
      "series": "The Pop Star Fairies",
      "description": "Rachel and Kirsty are in for a surprise when their fairy friends whisk them off to the Fairyland Music Festival, only for Jack Frost and his goblins to strut onto the stage! The naughty gang have stolen the Pop Star Fairies' magical notes, and Jack Frost intends to use them to become the biggest star in the world. They have to get them back - or concerts everywhere will be ruined!",
  },

  {
      "image": "Milly_9781408304808.jpg",
      "name": "Milly",
      "type": "the River Fairy",
      "series": "The Green Fairies",
      "description": "Rachel and Kirsty are excited for their holiday - but their hearts sink when they see their favourite beach is covered in litter. They visit the King and Queen of Fairyland , who introduce them to seven trainee fairies, the Green Fairies. But then Jack Frost turns up to steal their new wands! Rachel and Kirsty will have to get them back if they want the world to be clean...",
  },

  {
      "image": "Mimi_9781408342725.jpg",
      "name": "Mimi",
      "type": "the Laughter Fairy",
      "series": "The Friendship Fairies",
      "description": "Everyone knows Rachel and Kirsty are best friends. But when Jack Frost steals the Friendship Fairies' magical objects, the special bond of BFFs everywhere is at risk! Can they fight the Ice Lord and his goblin minions to restore the magic of friendship in both the Fairyland and worlds?",
  },

  {
      "image": "Miranda_9781408344095.jpg",
      "name": "Miranda",
      "type": "the Beauty Fairy",
      "series": "The Fashion Fairies ",
      "description": "Rachel and Kirsty are excited to be entering a charity fashion show where children get to show off their own designs. Unfortunately, Jack Frost thinks everyone in the world should dress like him, and steals the Fashion Fairies' seven magical items to put his naughty plan into action! Rachel and Kirsty must help the Fashion Fairies get them back, so that people everywhere can continue to look good and feel great.",
  },

  {
      "image": "Molly_9781408335642.jpg",
      "name": "Molly",
      "type": "the Goldfish Fairy",
      "series": "The Pet Keeper Fairies",
      "description": "Fairyland is in uproar! Jack Frost has stolen the Pet Fairies magical pets. Without them, the Pet Fairies can't ensure that pets in the human world find the right owners. And now the pets have escaped from Jack Frost's ice castle and are roaming the human world! Rachel and Kirsty have to help get the pets back...before it's too late.",
  },

  {
      "image": "Monica_9781408347218.jpg",
      "name": "Monica",
      "type": "the Marshmallow Fairy",
      "series": "The Candyland Fairies",
      "description": "Rachel and Kirsty are delighted to be invited to Fairyland for the annual Candy Harvest. But when the Candyland Fairies' magical objects go missing, they find themselves in a sticky situation! Can the girls defeat Jack Frost in time to save the Fairyland Sweet Factory from a bitter end?",
  },

  {
      "image": "Morgan_9781408309094.jpg",
      "name": "Morgan",
      "type": "the Midnight Fairy",
      "series": "The Twilight Fairies",
      "description": "Rachel and Kirsty are preparing for a midnight feast at Camp Stargaze, but everything's going wrong! Morgan the Midnight Fairy has lost her bag of magical night dust.The girls must find it before the midnight feast turns into a midnight flop!",
  },

  {
      "image": "Naomi_9781846168918.jpg",
      "name": "Naomi",
      "type": "the Netball Fairy",
      "series": "The Sporty Fairies",
      "description": "Everyone in Fairyland is preparing for the Fairy Olympics, but Jack Frost and his goblins have stolen the magic sporty items so they can win by cheating! And with the items missing, the human Olympics will be ruined too...can Rachel and Kirsty get the items back before it's too late?",
  },

  {
      "image": "Natalie_9781408312797.jpg",
      "name": "Natalie",
      "type": "the Christmas Stocking Fairy",
      "series": "Specials",
      "description": "Naughty Jack Frost never gets anything in his Christmas stocking, so this year he's decided nobody else will either! He's stolen the three magical objects that Natalie the Christmas Stocking Fairy needs to make sure children all over the world get their presents. Can Rachel and Kirsty find the precious items in time to keep Christmas magical?",
  },

  {
      "image": "Nicki_9781408325124.jpg",
      "name": "Nicki",
      "type": "the Holiday Camp Fairy",
      "series": "Specials",
      "description": "Holiday camp is one of the best things about summer, thanks to Nicki the Holiday Camp Fairy! Rachel and Kirsty have been looking forward to camp for months, but everything is going wrong. Can they help Nicki fix things, before the whole summer is ruined?",
  },

  {
      "image": "Nicole_9781408304747.jpg",
      "name": "Nicole",
      "type": "the Beach Fairy",
      "series": "The Green Fairies",
      "description": "Rachel and Kirsty are so excited about returning to Rainspell Island for a holiday! But their hearts sink when they go to their favourite beach and see that it's covered in litter. They're determined to clean it up, but they need a very special fairy friend to help them...",
  },

  {
      "image": "Nina_9781408325025.jpg",
      "name": "Nina",
      "type": "the Birthday Cake Fairy",
      "series": "The Sweet Fairies",
      "description": "Rachel and Kirsty are really excited - Kirsty's aunt works at Candy Land, and has brought them a big bag of sweets! But they all taste awful... Can the girls help the Sweet Fairies and stop Jack Frost so that sweets taste delicious again?",
  },

  {
      "image": "Olivia_9781846164613.jpg",
      "name": "Olivia",
      "type": "the Orchid Fairy",
      "series": "The Petal Fairies",
      "description": "The Petal Fairies have a very important job - together with their magic petals they help to make sure that flowers grow and bloom in the human world. But after Jack Frost and his goblins steal - and lose - the magic petals Rachel and Kirsty must help find them before the goblins get their hands on them!",
  },

  {
      "image": "Olympia_9781408315965.jpg",
      "name": "Olympia",
      "type": "the Games Fairy",
      "series": "Specials",
      "description": "Rachel and Kirsty are on an exciting day out to watch a triathlon - a three-part race where the athletes have to swim, cycle and run. But when the competitors start swimming round in circles, it's clear that all is not well. Olympia the Games Fairy appears and explains that Jack Frost has stolen the three magical items which she needs to make sure the Fairyland Games, which are also on at the moment, run smoothly. Without them, both the human and fairyland games are doomed to chaos!",
  },

  {
      "image": "Orla_9781408355145.jpg",
      "name": "Orla",
      "type": "the Inventor Fairy",
      "series": "The Discovery Fairies",
      "description": "Rachel and Kirsty are so excited to meet the Discovery Fairies, who look after some of the most exciting jobs in the world. But when Jack Frost steals Orla the Inventor Fairy's magical item, inventors everywhere stop having ideas!  Can the girls help Orla defeat him and bring inspiration back to fairy and human worlds?",
  },

  {
      "image": "Padma_9781408352441.jpg",
      "name": "Padma",
      "type": "the Pirate Fairy",
      "series": "Specials",
      "description": "Padma the Pirate Fairy is here to take Rachel and Kirsty on a wild adventure! The two best friends must hold on to their hats as they set off on a rollicking journey to defeat dastardly Jack Frost and his goblins.",
  },

  {
      "image": "Paige_9781846162091.jpg",
      "name": "Paige",
      "type": "the Pantomime Fairy",
      "series": "Specials",
      "description": "Paige the Pantomime Fairy is in trouble...She needs the help of Rachel and Kirsty to save Christmas and stop naughty Jack Frost from spoiling the celebrations for everyone!",
  },

  {
      "image": "Paloma_9781408349618.jpg",
      "name": "Paloma",
      "type": "the Dodgems Fairy",
      "series": "The Fun Fair Fairies",
      "description": "Kirsty is so excited to visit Rachel for a weekend and even more excited to visit The Fernandos' Fabulous Funfair! But Jack Frost wants a funfair all to himself and he's stolen the Funfair Fairies magic keyring to make it happen. Can Rachel and Kirsty defeat him, before the funfair is totally wrecked!",
  },

  {
      "image": "Pearl_9781843626350.jpg",
      "name": "Pearl",
      "type": "the Cloud Fairy",
      "series": "The Weather Fairies",
      "description": "Jack Frost has promised not to trouble the Rainbow Fairies again, but he didn’t say anything about the Weather Fairies! Now he has stolen the feathers from Doodle, the weather-vane cockerel in charge of the weather. It’s up to Rachel and Kirsty to get each of the feathers back!",
  },

  {
      "image": "Penelope_9781408345160.jpg",
      "name": "Penelope",
      "type": "the Foal Fairy",
      "series": "The Baby Farm Animal Fairies",
      "description": "Rachel and Kirsty are going to the Fluttering Fairyland Farm to meet the Baby Farm Animal Fairies. But when nasty Jack Frost casts a spell on the fairies' special pets, farm animals everywhere start behaving very strangely! Can the girls help the Baby Farm Animal Fairies put things right?",
  },

  {
      "image": "Penny_9781408335659.jpg",
      "name": "Penny",
      "type": "the Pony Fairy",
      "series": "The Pet Keeper Fairies",
      "description": "Fairyland is in uproar! Jack Frost has stolen the Pet Fairies magical pets. Without them, the Pet Fairies can't ensure that pets in the human world find the right owners. And now the pets have escaped from Jack Frost's ice castle and are roaming the human world! Rachel and Kirsty have to help get the pets back...before it's too late.",
  },

  {
      "image": "Perrie_9781408339510.jpg",
      "name": "Perrie",
      "type": "the Paramedic Fairy",
      "series": "The Helping Fairies",
      "description": "It's time for the Tippington Helper Award to be given and Rachel and Kirsty are excited to meet the nominees. Jack Frost has decided the winner, though, will be him! He's stolen all of the Helping Fairies' magical items - can Rachel and Kirsty get them back before the Tippington Helper Award is given to least helpful Ice Lord there ever was?",
  },

  {
      "image": "Phoebe_9781843628231.jpg",
      "name": "Phoebe",
      "type": "the Fashion Fairy",
      "series": "The Party Fairies",
      "description": "It's Queen Titania's and King Oberon's 1000th jubilee, and the seven Party Fairies are in charge of making the celebratory party extra-special! However, naughty Jack Frost has stolen each of the seven Party Fairies' magical party bags... if he succeeds the Fairyland celebrations will be ruined! Can Rachel and Kirsty help rescue the party bags so the magnificent jubilee cake can be finished in time...?",
  },

  {
      "image": "Pia_9781408308172.jpg",
      "name": "Pia",
      "type": "the Penguin Fairy",
      "series": "The Ocean Fairies",
      "description": "Rachel and Kirsty are visiting Kirsty's grandmother when they are whisked away to the South Pole to help Pia the Penguin Fairy. Everything is going well - until Jack Frost snatches Shannon the Ocean Fairy's conch shell and breaks it into seven pieces. Rachel and Kirsty, along with the Ocean Fairies, must get the pieces back before chaos takes over the oceans!",
  },

  {
      "image": "Pippa_9781846164583.jpg",
      "name": "Pippa",
      "type": "the Poppy Fairy",
      "series": "The Petal Fairies",
      "description": "The Petal Fairies have a very important job - together with their magic petals they help to make sure that flowers grow and bloom in the human world. But after Jack Frost and his goblins steal - and lose - the magic petals Rachel and Kirsty must help find them before the goblins get their hands on them!",
  },

  {
      "image": "Polly_9781843628224.jpg",
      "name": "Polly",
      "type": "the Party Fun Fairy",
      "series": "The Party Fairies",
      "description": "It's Queen Titania's and King Oberon's 1000th jubilee, and the seven Party Fairies are in charge of making the celebratory party extra-special! However, naughty Jack Frost has decided to sabotage the celebrations by hosting his own party, and in order to make his bigger and better than the king's and queen's he has sent his naughty goblins to steal each of the seven Party Fairies' magical party bags... if he succeeds the Fairyland celebrations will be ruined!",
  },

  {
      "image": "Poppy_9781408300336.jpg",
      "name": "Poppy",
      "type": "the Piano Fairy",
      "series": "The Music Fairies",
      "description": "The Music Fairies desperately need Rachel's and Kirsty's help! Jack Frost and his naughty goblins have stolen the fairies' Magical Musical Instruments, which means that music is being ruined for everyone! Jack Frost plans to use the instruments to help him win a national talent competition in the human world, and with the help of the enchanted instruments, he's bound to win. If this happens, humans will find out about Fairyland and then all the fairies will be in danger!",
  },

  {
      "image": "Priya_9781408355022.jpg",
      "name": "Priya",
      "type": "the Polar Bear Fairy",
      "series": "The Endangered Animals Fairies",
      "description": "Rachel and Kirsty are going to a very special safari park! They are very excited to help the endangered animals who live there. But naughty Jack Frost wants to steal the animals for himself! Can the girls help the Endangered Animals Fairies stop him?",
  },

  {
      "image": "Rae_9781408349632.jpg",
      "name": "Rae",
      "type": "the Rollercoaster Fairy",
      "series": "The Fun Fair Fairies",
      "description": "Kirsty is so excited to visit Rachel for a weekend and even more excited to visit The Fernandos' Fabulous Funfair! But Jack Frost wants a funfair all to himself and he's stolen the Funfair Fairies magic keyring to make it happen. Can Rachel and Kirsty defeat him, before the funfair is totally wrecked!",
  },

  {
      "image": "Rebecca_9781846164927.jpg",
      "name": "Rebecca",
      "type": "the Rock'n'Roll Fairy",
      "series": "The Dance Fairies",
      "description": "Rebecca desperately needs to get her dance ribbon back, as even the best rock 'n' roll dancers are doing a terrible job without it! To rescue the ribbon, though, Rachel and Kirsty will have to outwit not one, but two goblins!",
  },

  {
      "image": "Rihanna_9781408303528.jpg",
      "name": "Rihanna",
      "type": "the Seahorse Fairy",
      "series": "The Magical Animal Fairies",
      "description": "The Magical Animal Fairies look after seven young Magical Animals, and train them to use their powers wisely, for the good of Fairyland and the human world. But the animals have been stolen! Can Rachel and Kirsty help the Magical Animals before their powers cause chaos?",
  },

  {
      "image": "Rita_9781408355244.jpg",
      "name": "Rita",
      "type": "the Rollerskating Fairy",
      "series": "The After School Sports Fairies",
      "description": "When Jack Frost steals Rita the Rollerskating Fairy's magical object, roller skates everywhere start spinning out of control! Best friends Rachel and Kirsty must help Rita put things right, before anyone gets hurt.",
  },

  {
      "image": "Robyn_9781408327913.jpg",
      "name": "Robyn",
      "type": "the Christmas Party Fairy",
      "series": "Specials",
      "description": "It's Christmas Eve, and Rachel Walker and Kirsty Tate are very excited. They're helping to organize a big Christmas party! There's going to be jolly carols, a festive feast and a special ballet performance. Or that was the plan, until Jack Frost stole Robyn the Christmas Party Fairy's magical objects. Can the girls help Robyn find them, before the spirit of Christmas is lost forever?",
  },

  {
      "image": "Rochelle_9781408315941.jpg",
      "name": "Rochelle",
      "type": "the Star Spotter Fairy",
      "series": "The Pop Star Fairies",
      "description": "Rachel and Kirsty are in for a surprise when their fairy friends whisk them off to the Fairyland Music Festival, only for Jack Frost and his goblins to strut onto the stage! The naughty gang have stolen the Pop Star Fairies' magical notes, and Jack Frost intends to use them to become the biggest star in the world. They have to get them back - or concerts everywhere will be ruined!",
  },

  {
      "image": "Rosalie_9781408340349.jpg",
      "name": "Rosalie",
      "type": "the Rapunzel Fairy",
      "series": "The Storybook Fairies",
      "description": "Rachel and Kirsty are very excited to be going to the Wetherbury Storytelling Festival! But when mean Jack Frost steals the Storybook Fairies' magical objects, all the stories start getting mixed up. Can the girls help their fairy friends put things right?",
  },

  {
      "image": "Rosie_9781408327982.jpg",
      "name": "Rosie",
      "type": "the Honey Bear Fairy",
      "series": "The Baby Animal Rescue Fairies",
      "description": "Kirsty Tate and Rachel Walker are having a fantastic time helping out at the Wild Woods Nature Reserve. But nasty Jack Frost has stolen the Animal Rescue Fairies' magical key rings, so he can have his own personal zoo. Rosie the Honey Bear Fairy needs Rachel and Kirsty's help! If she can't get her magical key ring back, then Billy the bear cub will be whisked off to the Ice Lord's zoo, never to be seen again...",
  },

  {
      "image": "Roxie_9781408331538.jpg",
      "name": "Roxie",
      "type": "the Baking Fairy",
      "series": "The Magical Craft Fairies",
      "description": "Rachel and Kirsty return to Rainspell Island for Crafts Week. They’re so excited try all of the workshops. But after the Magical Crafts Fairies’ items are stolen, all types of crafts are in chaos. Jewellery will keep breaking, artists won’t be able to draw properly and paint will come out grey! Can they help the Magical Crafts Fairies get them back before Crafts Week is ruined?",
  },

  {
      "image": "Ruby_9781843620167.jpg",
      "name": "Ruby",
      "type": "the Red Fairy",
      "series": "The Rainbow Fairies",
      "description": "When Rachel and Kirsty meet on the ferry to Rainspell Island they have no idea the incredible magical adventures in store for them! Join the two best friends as they meet Ruby the Red Fairy, the first fairy to introduce them to the world of Rainbow Magic.",
  },

  {
      "image": "Ruth_9781408340523.jpg",
      "name": "Ruth",
      "type": "the Red Riding Hood Fairy",
      "series": "The Storybook Fairies",
      "description": "Rachel and Kirsty are very excited to be going to the Wetherbury Storytelling Festival! But when mean Jack Frost steals the Storybook Fairies' magical objects, all the stories start getting mixed up. Can the girls help their fairy friends put things right?",
  },

  {
      "image": "Sabrina_9781408309124.jpg",
      "name": "Sabrina",
      "type": "the Sweet Dreams Fairy",
      "series": "The Twilight Fairies",
      "description": "It's Rachel and Kirsty's last night at Camp Stargaze, but horrible dreams are keeping them awake! The girls know they must find Sabrina the Sweet Dreams Fairy's bag of dream dust. It's time to pay a visit to mean Jack Frost's Ice Castle...",
  },

  {
      "image": "Sadie_9781408300329.jpg",
      "name": "Sadie",
      "type": "the Saxophone Fairy",
      "series": "The Music Fairies",
      "description": "The Music Fairies desperately need Rachel's and Kirsty's help! Jack Frost and his naughty goblins have stolen the fairies' Magical Musical Instruments, which means that music is being ruined for everyone! Jack Frost plans to use the instruments to help him win a national talent competition in the human world, and with the help of the enchanted instruments, he's bound to win. If this happens, humans will find out about Fairyland and then all the fairies will be in danger!",
  },

  {
      "image": "Saffron_9781843620181.jpg",
      "name": "Saffron",
      "type": "the Yellow Fairy",
      "series": "The Rainbow Fairies",
      "description": "When Rachel and Kirsty meet on the ferry to Rainspell Island they have no idea the incredible magical adventures in store for them! Join the friends as they help Saffron the Yellow Fairy restore colour to Fairyland.",
  },

  {
      "image": "Samantha_9781846168925.jpg",
      "name": "Samantha",
      "type": "the Swimming Fairy ",
      "series": "The Sporty Fairies",
      "description": "Everyone in Fairyland is preparing for the Fairy Olympics, but Jack Frost and his goblins have stolen the magic sporty items so they can win by cheating! And with the items missing, the human Olympics will be ruined too...can Rachel and Kirsty get the items back before it's too late?",
  },

  {
      "image": "Samira_9781408347188.jpg",
      "name": "Samira",
      "type": "the Superhero Fairy",
      "series": "Specials",
      "description": "Samira the Superhero Fairy makes sure superheroes everywhere have the spark they need to save the day, every day! But when Jack Frost and his goblins steal her magical objects, no one is feeling very super. Can Rachel and Kirsty help Samira be a hero again?",
  },

  {
      "image": "Sarah_9781846161940.jpg",
      "name": "Sarah",
      "type": "the Sunday Fairy",
      "series": "The Fun Day Fairies",
      "description": "Naughty Jack Frost has stolen the seven Fairyland Fun Day Flags! Rachel and Kirsty have helped the fairies track down six of the flags, but now Sarah needs their help to find hers so that all days of the week are fun again.",
  },

  {
      "image": "Sasha_9781408358641.jpg",
      "name": "Sasha",
      "type": "the Slime Fairy",
      "series": "Specials",
      "description": "Sasha the Slime Fairy's special magic helps everyone have loads of fun making and playing with slime. From glitter slime to goo slime, all types of slime are Sasha's favourite! But when Jack Frost steals her three special pieces of slime, the magic starts to go awry. Can Rachel and Kirsty help her get them back before the slime adventure comes to a sticky end?",
  },

  {
      "image": "Saskia_9781846164965.jpg",
      "name": "Saskia",
      "type": "the Salsa Fairy",
      "series": "The Dance Fairies",
      "description": "Jack Frost's naughty goblins have stolen the Dance Fairies' magic ribbons and are hiding in the human world. Everywhere dance is being ruined! Rachel and Kirsty must help the fairies but the goblins' new powers won't make it easy...",
  },

  {
      "image": "Savannah_9781408327968.jpg",
      "name": "Savannah",
      "type": "the Zebra Fairy",
      "series": "The Baby Animal Rescue Fairies",
      "description": "Kirsty Tate and Rachel Walker are helping out at the Wild Woods Nature Reserve. They’re having a great time meeting cute animals and making friends with The Baby Animal Rescue Fairies. But when Jack Frost decides he wants baby animals for his own personal zoo, he steals the fairies’ magical key rings. Can the girls help the fairies get their key rings back before the baby animals are trapped inside the Ice Lord’s zoo for ever?",
  },

  {
      "image": "Scarlett_9781843629542.jpg",
      "name": "Scarlett",
      "type": "the Garnet Fairy",
      "series": "The Jewel Fairies",
      "description": "Disaster has struck Fairyland! Jack Frost has stolen all the jewels from Queen Titania's crown. Without them, the fairies' magic dust is losing its power. Rachel and Kirsty have to help the Jewel Fairies to get the jewels back... before it's too late.",
  },

  {
      "image": "Selena_9781408312858.jpg",
      "name": "Selena",
      "type": "the Sleepover Fairy",
      "series": "Specials",
      "description": "Selena the Sleepover Fairy makes sure that sleepovers are full of fun and games for everyone! But when Jack Frost's naughty goblins steal her magical sleepover objects, all sleepovers are in danger of being ruined, including the giant sleepover that Rachel and Kirsty are attending!",
  },

  {
      "image": "Selma_9781408355046.jpg",
      "name": "Selma",
      "type": "the Snow Leopard Fairy",
      "series": "The Endangered Animals Fairies",
      "description": "Rachel and Kirsty are going to a very special safari park! They are very excited to help the endangered animals who live there. But naughty Jack Frost wants to steal the animals for himself! Can the girls help the Endangered Animals Fairies stop him?",
  },

  {
      "image": "Shannon_9781408300251.jpg",
      "name": "Shannon",
      "type": "the Ocean Fairy",
      "series": "Specials",
      "description": "Shannon the Ocean Fairy needs Rachel and Kirsty's help! Naughty Jack Frost has stolen her three enchanted pearls, and this is causing chaos in the oceans. To stop him, the girls must explore an underwater world and meet some very special friends...the narwhals!",
  },

  {
      "image": "Shelley_9781408347270.jpg",
      "name": "Shelley",
      "type": "the Sherbet Fairy",
      "series": "The Candyland Fairies",
      "description": "Rachel and Kirsty are delighted to be invited to Fairyland for the annual Candy Harvest. But when the Candyland Fairies' magical objects go missing, they find themselves in a sticky situation! Can the girls defeat Jack Frost in time to save the Fairyland Sweet Factory from a bitter end?",
  },

  {
      "image": "Sianne_9781408351680.jpg",
      "name": "Sianne",
      "type": "the Butterfly Fairy",
      "series": "Specials",
      "description": "Sianne the Butterfly Fairy has a very special job. She provides butterflies with the special magic they need to carry messages between the fairy and human worlds. But when Jack Frost steals her enchanted objects, the connection between the two worlds is at risk. Can Rachel and Kirsty help defeat the wicked Ice Lord before it's too late?",
  },

  {
      "image": "Sienna_9781846161933.jpg",
      "name": "Sienna",
      "type": "the Saturday Fairy",
      "series": "The Fun Day Fairies",
      "description": "Naughty Jack Frost has stolen the seven Fairyland Fun Day Flags! In this book, Sienna the Saturday Fairy needs Rachel and Kirsty's help to track down her flag before the local fashion show is ruined by hordes of marauding goblins...",
  },

  {
      "image": "Sky_9781843620204.jpg",
      "name": "Sky",
      "type": "the Blue Fairy",
      "series": "The Rainbow Fairies",
      "description": "When Rachel and Kirsty meet on the ferry to Rainspell Island one summer holiday, they have no idea that such magical adventures with the fairies await them! in this exciting story, the girls need to find Sky before the naughty goblins track her down...",
  },

  {
      "image": "Sophia_9781408303535.jpg",
      "name": "Sophia",
      "type": "the Snow Swan Fairy",
      "series": "The Magical Animal Fairies",
      "description": "The Magical Animal Fairies look after seven young Magical Animals, and train them to use their powers wisely, for the good of Fairyland and the human world. But the animals have been stolen! Can Rachel and Kirsty help the Magical Animals before their powers cause chaos?",
  },

  {
      "image": "Sophie_9781843629535.jpg",
      "name": "Sophie",
      "type": "the Sapphire Fairy",
      "series": "The Jewel Fairies",
      "description": "Disaster has struck Fairyland! Jack Frost has stolen all the jewels from Queen Titania's crown. Without them, the fairies' magic dust is losing its power. Rachel and Kirsty have to help the Jewel Fairies to get the jewels back... before it's too late.",
  },

  {
      "image": "Stella_9781843628699.jpg",
      "name": "Stella",
      "type": "the Star Fairy",
      "series": "Specials",
      "description": "Stella the Star Fairy is all in a flutter, trying to put the sparkle into Christmas. Stella looks after all the special Christmas lights - from the overhead illuminations to the Christmas tree fairy lights, right through to the twinkling stars. Three magic Christmas baubles control all of these elements but now Jack Frost's goblins have stolen them. There's not going to be any Christmas atmosphere and Santa won't even be able to guide his sleigh through the night sky. Stella the Star Fairy needs Rachel and Kirsty's help to get the baubles back.",
  },

  {
      "image": "Stephanie_9781408308196.jpg",
      "name": "Stephanie",
      "type": "the Starfish Fairy",
      "series": "The Ocean Fairies",
      "description": "Rachel and Kirsty are visiting Kirsty's grandmother when they are whisked away to Fairyland for the yearly Ocean Gala. Here, Shannon the Ocean Fairy plays her Magical Golden Conch Shell to ensure that the seas and oceans remain peaceful and ordered for the year ahead. Everything is going well - until Jack Frost snatches the conch shell and breaks it into seven pieces. Rachel and Kirsty, along with the Ocean Fairies, must get the pieces back before chaos takes over the oceans!",
  },

  {
      "image": "Storm_9781843626374.jpg",
      "name": "Storm",
      "type": "the Lightning Fairy ",
      "series": "The Weather Fairies",
      "description": "Jack Frost has promised not to trouble the Rainbow Fairies again, but he didn’t say anything about the Weather Fairies! Now he has stolen the feathers from Doodle, the weather-vane cockerel in charge of the weather. It’s up to Rachel and Kirsty to get each of the feathers back!",
  },

  {
      "image": "Summer_9781843629603.jpg",
      "name": "Summer",
      "type": "the Holiday Fairy",
      "series": "Specials",
      "description": "Summer the Holiday Fairy has got her work cut out. Jack Frost has moved to Rainspell Island for the summer and it's a very different place to when Rachel and Kirsty visited in the first series. Now there are no tinkling ice-cream vans or sailing boats. There's not even any sand on the beach! Jack Frost has taken it all to build his giant sandcastle. There are three special shells, that control the holiday atmosphere. Summer the Holiday Fairy needs Rachel and Kirsty's help to get them back.",
  },

  {
      "image": "Susie_9781408345085.jpg",
      "name": "Susie",
      "type": "the Sister Fairy",
      "series": "Specials",
      "description": "Susie helps make sure the magical bond between all sisters is strong and true. But when her three special objects go missing, big and little sisters everywhere start falling out! Could this be the work of nasty Jack Frost? Rachel, Kirsty and Susie must work together to defeat him. There's a diary planner, a padlock and a mobile phone in this book.",
  },

  {
      "image": "Tallulah_9781846161896.jpg",
      "name": "Tallulah",
      "type": "the Tuesday Fairy",
      "series": "The Fun Day Fairies",
      "description": "Naughty Jack Frost has stolen the seven Fairyland Fun Day Flags, and the Fun Day fairies need Rachel and Kirsty's help to find them! Can they find the flags before the fun is sapped from every single day?",
  },

  {
      "image": "Tamara_9781408316870.jpg",
      "name": "Tamara",
      "type": "the Tooth Fairy",
      "series": "Specials",
      "description": "When Jack Frost steals her magic items in an attempt to ward off toothache, Tamara the Tooth Fairy is no longer able to collect teeth from beneath children's pillows! But as ever, Rachel and Kirsty are eager to help. Can they find all three missing objects - and persuade Jack Frost to go to the dentist?",
  },

  {
      "image": "Tasha_9781846164934.jpg",
      "name": "Tasha",
      "type": "the Tap Dance Fairy",
      "series": "The Dance Fairies",
      "description": "Rachel and Kirsty are on the hunt for Tasha's magic ribbon. It's been stolen by a goblin, who thinks he's brilliant at tap dancing! Can the girls find a way to trick the nasty goblin into giving the ribbon back?",
  },

  {
      "image": "Taylor_9781408312902.jpg",
      "name": "Taylor",
      "type": "the Talent Show Fairy",
      "series": "The Showtime Fairies",
      "description": "Jack Frost has stolen the Showtime Fairies' magic stars, which means everyone is losing their special talent! Can Rachel and Kirsty get the stars back before the Tippington Variety Show is ruined?",
  },

  {
      "image": "Teri_9781408355206.jpg",
      "name": "Teri",
      "type": "the Trampolining Fairy",
      "series": "The After School Sports Fairies",
      "description": "Everyone is very excited for the opening of the Tippington Leisure Centre! Teri the Trampolining Fairy is full of bounce, until Jack Frost steals her magical item. Can Rachel and Kirsty help Teri defeat him and put back the spring in her step?",
  },

  {
      "image": "Tess_9781408308189.jpg",
      "name": "Tess",
      "type": "the Sea Turtle Fairy",
      "series": "The Ocean Fairies",
      "description": "Rachel and Kirsty are visiting Kirsty's grandmother when they are whisked away to Fairyland for the yearly Ocean Gala. Here, Shannon the Ocean Fairy plays her Magical Golden Conch Shell to ensure that the seas and oceans remain peaceful and ordered for the year ahead. Everything is going well - until Jack Frost snatches the conch shell and breaks it into seven pieces. Rachel and Kirsty, along with the Ocean Fairies, must get the pieces back before chaos takes over the oceans!",
  },

  {
      "image": "Thea_9781408335697.jpg",
      "name": "Thea",
      "type": "the Thursday Fairy",
      "series": "The Fun Day Fairies",
      "description": "Naughty Jack Frost has stolen the seven Fairyland Fun Day Flags! In this book, Thea the Thursday Fairy needs Rachel and Kirsty's help to rescue her flag from an aquarium... but the girls must watch out for the sharks!",
  },

  {
      "image": "Tia_9781846164576.jpg",
      "name": "Tia",
      "type": "the Tulip Fairy",
      "series": "The Petal Fairies",
      "description": "The Petal Fairies have a very important job - together with their magic petals they help to make sure that flowers grow and bloom in the human world. But after Jack Frost and his goblins steal - and lose - the magic petals Rachel and Kirsty must help find them before the goblins get their hands on them!",
  },

  {
      "image": "Tiana_9781408349779.jpg",
      "name": "Tiana",
      "type": "the Toy Fairy",
      "series": "Specials",
      "description": "Tiana's special magic looks after toys and fun times everywhere. But when naughty Jack Frost steals her shiny magical marble, toys everywhere stop working the way they should, and ruin everyone's fun! Rachel and Kirsty must travel with Tiana to the magical Land of Toys and help find Jack Frost and his goblins, and get the marble back.",
  },

  {
      "image": "Tilly_9781408333792.jpg",
      "name": "Tilly",
      "type": "the Teacher Fairy",
      "series": "Specials",
      "description": "Tilly the Teacher Fairy brings happiness to schools everywhere. But it all starts to go wrong when naughty Jack Frost steals her three magical objects! Can Rachel and Kirsty stop him and make school fun for everyone again? There's a fairy laptop, a magic apple and a twinkly folder inside this book. Read on to see if Rachel, Kirsty and Tilly can find them!",
  },

  {
      "image": "Trixie_9781408333013.jpg",
      "name": "Trixie",
      "type": "the Halloween Fairy",
      "series": "Specials",
      "description": "Trixie the Halloween Fairy makes sure that Halloween is fun-filled and exciting for everyone! But when a gang of greedy goblins steal Trixie's Enchanted Sweets, all Halloween plans are thrown into chaos. Can Rachel and Kirsty help Trixie find the Enchanted Sweets before this special day is ruined for everyone?",
  },

  {
      "image": "Tyra_9781408316764.jpg",
      "name": "Tyra",
      "type": "the Dress Designer Fairy",
      "series": "The Fashion Fairies",
      "description": "Rachel and Kirsty are excited to be entering a charity fashion show where children get to show off their own designs. Unfortunately, Jack Frost thinks everyone in the world should dress like him, and steals the Fashion Fairies' seven magical items to put his naughty plan into action! Rachel and Kirsty must help the Fashion Fairies get them back, so that people everywhere can continue to look good and feel great.",
  },

  {
      "image": "Una_9781408315958.jpg",
      "name": "Una",
      "type": "the Concert Fairy",
      "series": "The Pop Star Fairies",
      "description": "Rachel and Kirsty are in for a surprise when their fairy friends whisk them off to the Fairyland Music Festival, only for Jack Frost and his goblins to strut onto the stage! The naughty gang have stolen the Pop Star Fairies' magical notes, and Jack Frost intends to use them to become the biggest star in the world. They have to get them back - or concerts everywhere will be ruined!",
  },

  {
      "image": "Vanessa_9781408315910.jpg",
      "name": "Vanessa",
      "type": "the Dance Steps Fairy",
      "series": "The Pop Star Fairies",
      "description": "Rachel and Kirsty are in for a surprise when their fairy friends whisk them off to the Fairyland Music Festival, only for Jack Frost and his goblins to strut onto the stage! The naughty gang have stolen the Pop Star Fairies' magical notes, and Jack Frost intends to use them to become the biggest star in the world. They have to get them back - or concerts everywhere will be ruined!",
  },

  {
      "image": "Victoria_9781408300275.jpg",
      "name": "Victoria",
      "type": "the Violin Fairy",
      "series": "The Music Fairies",
      "description": "The Music Fairies desperately need Rachel's and Kirsty's help! Jack Frost and his naughty goblins have stolen the fairies' Magical Musical Instruments, which means that music is being ruined for everyone! Jack Frost plans to use the instruments to help him win a national talent competition in the human world, and with the help of the enchanted instruments, he's bound to win. If this happens, humans will find out about Fairyland and then all the fairies will be in danger!",
  },

  {
      "image": "Violet the Painting Fairy.jpeg",
      "name": "Violet",
      "type": "the Painting Fairy  ",
      "series": "The Magical Craft Fairies",
      "description": "Rachel and Kirsty return to Rainspell Island for Crafts Week. They’re so excited try all of the workshops. But after the Magical Crafts Fairies’ items are stolen, all types of crafts are in chaos. Jewellery will keep breaking, artists won’t be able to draw properly and paint will come out grey! Can they help the Magical Crafts Fairies get them back before Crafts Week is ruined?",
  },

  {
      "image": "Whitney_9781408308202.jpg",
      "name": "Whitney",
      "type": "the Whale Fairy",
      "series": "The Ocean Fairies",
      "description": "Rachel and Kirsty are visiting Kirsty's grandmother when they are whisked away to Fairyland for the yearly Ocean Gala. Here, Shannon the Ocean Fairy plays her Magical Golden Conch Shell to ensure that the seas and oceans remain peaceful and ordered for the year ahead. Everything is going well - until Jack Frost snatches the conch shell and breaks it into seven pieces. Rachel and Kirsty, along with the Ocean Fairies, must get the pieces back before chaos takes over the oceans!",
  },

  {
      "image": "Willow_9781846161902.jpg",
      "name": "Willow",
      "type": "the Wednesday Fairy",
      "series": "The Fun Day Fairies",
      "description": "Naughty Jack Frost has stolen the seven Fairyland Fun Day Flags, and the Fun Day fairies need Rachel and Kirsty's help to find them! In this book, Willow the Wednesday Fairy must find her flags before the goblins sabotage the Arts and Craft fair...",
  },

  {
      "image": "Yasmin_9781408309100.jpg",
      "name": "Yasmin",
      "type": "the Nightowl Fairy",
      "series": "The Twilight Fairies",
      "description": "Rachel and Kirsty are thrilled to be at Camp Stargaze. But Jack Frost has stolen the Twilight Fairies' magical bags, causing night-time chaos! Can the girls find all the bags of dust and restore the night to the way it's supposed to be?",
  },

  {
      "image": "Zadie_9781408331453.jpg",
      "name": "Zadie",
      "type": "the Sewing Fairy",
      "series": "The Magical Craft Fairies",
      "description": "Rachel and Kirsty return to Rainspell Island for Crafts Week. They’re so excited try all of the workshops. But after the Magical Crafts Fairies’ items are stolen, all types of crafts are in chaos. Jewellery will keep breaking, artists won’t be able to draw properly and paint will come out grey! Can they help the Magical Crafts Fairies get them back before Crafts Week is ruined?",
  },

  {
      "image": "Zainab_9781408359945.jpg",
      "name": "Zainab",
      "type": "the Squishy Toy Fairy",
      "series": "Specials",
      "description": "Zainab the Squishy Toy Fairy's special magic makes sure everyone has fun playing with squishy toys. But when her magical object goes missing, squishies everywhere stop bouncing back! Could this be the work of naughty Jack Frost? Rachel, Kirsty and Zainab must help put things right.",
  },

  {
      "image": "Zara_9781408309087.jpg",
      "name": "Zara",
      "type": "the Starlight Fairy",
      "series": "The Twilight Fairies",
      "description": "Strange things are happening to the stars at Camp Stargaze, and without Zara the Starlight Fairy's bag of stardust, the night sky will be ruined forever. But can Rachel and Kirsty help her to find it before the goblins do? The race is on!",
  },

  {
      "image": "Zoe_9781846168901.jpg",
      "name": "Zoe",
      "type": "the Skating Fairy",
      "series": "The Sporty Fairies",
      "description": "Everyone in Fairyland is preparing for the Fairy Olympics, but Jack Frost and his goblins have stolen the magic sporty items so they can win by cheating! And with the items missing, the human Olympics will be ruined too...can Rachel and Kirsty get the items back before it's too late?",
  },

  /* Please add new fairies below. Each fairy consists of:
    - 'image': the name of the image in the BOOK_COVERS folder
    - 'name': the name of the fairy
    - 'type': the title of the fairy (eg. "The breeze fairy")
    - 'series': the book series that this fairy belongs to
    - 'description': a description of the book
  */
  /* DO NOT edit above this line */



  /* DO NOT edit below this line */
]

export default Fairies;
