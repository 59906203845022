import rectangle from './images/Fairyland-banner.png';

// import elsie from './images/Elsie-Engineer.png';
import fairy2 from './images/Zainab-01.png';
import fairy3 from './images/CodingFairy.png';
// import fairy4 from './images/Ellie.png'
import girl1 from './images/Girl1.png';
import girl2 from './images/Girl2.png';

import './Common.css';
import './Main.css';

function Main(props) {
  const rootPath = '/rainbow-fairies/#';

  return (
    <div>
      <div className="Mid-Banner">
        <img src={rectangle} className="Background" alt="fairy land" />
        {/* NOTE: Need to make the shadow a separate element since the background is an <img> */}
        <div className="Shadow"> </div>
      </div>
      <div className="Mid-Banner Welcome-Banner">
        <img src={girl2} className="WelcomeGirl" alt="Girl"></img>
        <div className="WelcomeTextContainer">
          <h1 className="WelcomeText">Welcome to the world of Rainbow Magic!</h1>
          <p className="Body-Text">
            We are so excited to share this magical world with you and can’t wait for you to meet all our fairy friends.
            <br/><br/>
            Come on in!
          </p>
        </div>
        <img src={girl1} className="WelcomeGirl" alt="Girl"></img>
      </div>
      <div className="Mid-Banner ContentLink-Banner">
        <div className="ContentLink-Container">
          {/*<a className="Content-Link" href={rootPath + '/printables'}>
            <div className="BackCircle Circle1"></div>
            <img src={elsie} alt="engineer fairy"></img>
            <div className="ContentLink-Text">
              <p>Activities</p>
            </div>
          </a>*/}
          <a className="Content-Link" href={rootPath + '/fairies'}>
            <div className="BackCircle Circle2"></div>
            <img src={fairy2} alt="rainbow fairy"></img>
            <div className="ContentLink-Text">
              <p>Fairies A-Z</p>
            </div>
          </a>
          <a className="Content-Link" href={rootPath + '/findfairy'}>
            <div className="BackCircle Circle3"></div>
            <img src={fairy3} alt="coding fairy"></img>
            <div className="ContentLink-Text">
              <p>Find a Fairy</p>
            </div>
          </a>
          {/* TODO: Embedded youtube videos (post-launch) */}
          {/*<a className="Content-Link" href='/videos'>
            <div className="BackCircle Circle4"></div>
            <img src={fairy4} alt="guitar fairy"></img>
            <div className="ContentLink-Text">
              <p>Videos</p>
            </div>
          </a>*/}
        </div>
      </div>
    </div>
  );
}

export default Main;
