import logo from './images/RainbowLogo.png';
import headerLeft from './images/FairyHeaderLeft.png';
import headerRight from './images/FairyHeaderRight.png';

import './Header.css';

function Header() {
  return (
    <header className="App-header">
      <img src={headerLeft} className="Fairy-img" alt="fairies!" />
      {/* TODO: May need to un-hardcode the root path later on */}
      <a href='/rainbow-fairies/'>
        <img src={logo} className="RainbowLogo" alt="rainbow" />
      </a>
      <img src={headerRight} className="Fairy-img" alt="more fairies!" />
    </header>
  );
}

export default Header;
