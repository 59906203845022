import termsX from './images/terms-x.png'
import termsAndConditions from './RainbowMagicOnline-TERMS and CONDITIONS.pdf'

import './Terms.css'

function getTermsAndConditions() {
  // TODO: Real terms and conditions!
  return (
    <div>
    <a href={termsAndConditions} target="_blank" rel="noopener noreferrer">test.pdf</a>
  </div>);
}

function getPrivacyPolicy() {
  // TODO: Real privacy policy!
  return (<div className="Terms-Desc">Privacy Policy of Website Use Effective Date: FEBRUARY 14, 2013 The Website (defined below) is provided by Mattel, Inc., and its subsidiaries, including, without limitation, Fisher-Price, Inc. and American Girl Brands, LLC (collectively, "Mattel", "we", "our", or "us"). These "Terms and Conditions of Website Use" (this "User Agreement") govern your use of the Website, regardless of how you access or use it. By "Website", we mean the Internet domain address within which this User Agreement is posted and all features, applications, content, and downloads that are operated by us and that are available through or interact with it, and/or post links to this User Agreement. IF YOU'RE UNDER THE AGE OF MAJORITY (which is 18 years old in most states), then your parent or guardian may be liable for some or all of your activities on the Website. Because of this, and because it’s prudent for your parent or guardian to be aware of your activities, including the websites that you visit, you should make your parent or guardian aware that you are using the Website – as this User Agreement and your use of the Website affect their legal rights and obligations. IF YOU WANT TO USE THIS WEBSITE, then carefully read this User Agreement, as it constitutes a written agreement between you and Mattel and it affects your legal rights and obligations. Each time you access and/or use the Website (other than to simply read this User Agreement), you agree to be bound by and comply with all of the terms of this User Agreement and any Additional Terms (defined below). Therefore, do not use the Website if you do not agree to all of the terms of this User Agreement and any Additional Terms.</div>);
}

function TermsBanner(props) {
  return (<div>
    <div className="Terms-Shadow" onClick={props.hideTerms}></div>
    <div className="Terms-Banner">
      <div className="Terms-Ex" onClick={props.hideTerms}><img src={termsX} alt="exit"></img></div>
      {props.isPrivacy ? getPrivacyPolicy() : getTermsAndConditions()}
    </div>
  </div>);
}

export default TermsBanner;
