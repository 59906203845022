import fairy1 from '../images/Fairy-01.png';

import './Tile.css';

import useOnScreen from '../onScreenHook.jsx'
import {useRef, useState} from 'react';

const requestImageFile = require.context('../BOOK_COVERS', false, /.jpe?g/);

async function fetchImage( name ) {
  const module = requestImageFile( `./${name}` );
  // NOTE: `require` returns the full module. default returns the URL
  return module.default;
}

function updateImage(image, setImage) {
  if (image) {
    fetchImage(image).then(i => {
      setImage(i)
    });
  } else {
    setImage(fairy1);
  }
}

/* FairyIndex/Tile.jsx */
function Tile(props) {
  const [image, setImage] = useState(undefined);

  const ref = useRef(null);
  const isVisible = useOnScreen(ref)
  if (isVisible) {
    updateImage(props.image, setImage)
  } else if (image !== undefined) {
    // Reset the image if the component goes off-screen
    setImage(undefined);
  }

  return (<div className="FairyIndex_tile">
    <div className="TileInner" ref={ref}>
      <div className="TileFront">
        {image
          ? <img src={image} alt={props.altText} className="FairyIndex_tile-bgd"></img>
          : <div className="FairyIndex_tile-skeleton"></div>}
        <div className="FairyIndex_tile-textWrapper">
          <p className="FairyIndex_tile-fairyName">{props.name}</p>
          <p className="FairyIndex_tile-fairyTitle">{props.title}</p>
        </div>
      </div>
      <div className="TileBack">
        <div className="FairyIndex_tile-textWrapper">
          <p className="FairyIndex_tile-bookSeries">{props.series}</p>
          <p className="FairyIndex_tile-fairyName">{props.name}</p>
          <p className="FairyIndex_tile-fairyTitle">{props.title}</p>
        </div>
        <p className="FairyIndex_tile-desc">{props.bio}</p>
      </div>
    </div>
  </div>);
}

export default Tile;
