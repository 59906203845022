import Header from './Header.jsx'
import Footer from './Footer.jsx'

import Main from './Main.jsx'
import Printables from './Printables/Printables.jsx';
import Fairies from './FairyIndex/Fairies.jsx';
import FairyFinder from './FindFairy/FairyFinder.jsx';
import Terms from './Terms.jsx'
import ScrollToTop from './ScrollToTop.jsx'

import './App.css';

import {useState} from 'react';

import Protect from 'react-app-protect'
import 'react-app-protect/dist/index.css'

import {
  HashRouter as Router,
  Switch,
  Route
} from "react-router-dom";

function App() {
  const [showTerms, setShowTerms] = useState(false);
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);

  const router = (<Router basename="/">
    <ScrollToTop />
    <Switch>
      <Route path="/fairies">
        <Fairies />
      </Route>
      <Route path="/findfairy">
        <FairyFinder />
      </Route>
      <Route path="/printables">
        <Printables/>
      </Route>
      <Route path="/">
        <Main/>
      </Route>
    </Switch>
  </Router>);

  let termsBanner = null;
  if (showTerms) {
    termsBanner = <Terms hideTerms={() => setShowTerms(false)} />;
  } else if (showPrivacyPolicy) {
    termsBanner = <Terms isPrivacy hideTerms={() => setShowPrivacyPolicy(false)} />;
  }

  return (
    <Protect sha512='03edc253fa2b686c5e503b70f9462b7e4b505f1113e98ce36b19c39c36a60f9550eed73c789f2e3287be07fb8b5605ff467ecf9146a23914b34468dea3ac6df7'>
      <div className="App">
        {termsBanner}
        <Header />
        {router}
        <Footer
          renderTerms={() => setShowTerms(true)}
          renderPrivacy={() => setShowPrivacyPolicy(true)}
        />
      </div>
    </Protect>
  );
}

export default App;
