import React, { Component } from 'react';

import tyra from '../images/Tyra.png';

import '../Common.css';
import './Fairies.css';

import Tile from './Tile.jsx';
import Grid from '../Grid.jsx';

import tileData from '../FAIRIES.js';

class Fairies extends Component {
  render() {
    const refMap = {};
    const tiles = tileData.map((item) => {
      refMap[item.name] = React.createRef(item.name);
      // Wrapper div to hold the ref
      return (<div key={item.name} ref={refMap[item.name]}>
        <Tile
          image={item.image}
          name={item.name}
          title={item.type}
          bio={item.description}
          series={item.series}
        />
      </div>);
    });

    const nextLetter = (letter) => {
      return String.fromCharCode( letter.charCodeAt(0) + 1 );
    }

    const scrollToFairyForLetter = (letter) => {
      const fairiesWithLetter = tileData.filter(td => td.name[0].toLowerCase() === letter);
      if (fairiesWithLetter.length === 0) {
        if (letter === 'z') return; // sanity check
        scrollToFairyForLetter( nextLetter(letter) )
        return;
      }
      const tileRef = refMap[fairiesWithLetter[0].name];
      tileRef.current.scrollIntoView()
    }

    const alphabet = 'abcdefghijklmnopqrstuvwxyz';
    const letterButtons = alphabet.split("").map(l =>
      <div className="LetterButton" key={l} onClick={() => scrollToFairyForLetter(l)}>
        {l.toUpperCase()}
      </div>
    );

    return <div>
      <div className="FairyWrapper FairyIndexFairyWrapper">
        <img src={tyra} className="DecorFairy" alt="glasses fairy"></img>
      </div>
      <div className="ContentBackgroundWrapper">
        <div className="ContentBackgroundTop FairyIndexBackgroundTop"></div>
        <div className="ContentBackground FairyIndexBackground"></div>
      </div>
      <h1 className="ContentTitle FairyIndex-Title">Fairies A-Z</h1>
      <div className="FairyIndexGridWrapper">
        <Grid tiles={tiles} />
      </div>
      <div className="AToZSlider">
        {letterButtons}
      </div>
    </div>;
  }
}

export default Fairies;
