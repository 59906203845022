import fairy1 from '../images/Fairy-01.png';

import './Tile.css';

/* Printables/Tile.jsx */
function Tile(props) {
  const image = props.image || fairy1;

  return <div className="Printable_tile">
    <img src={image} alt={props.altText} className="Printable_tile-bgd"></img>
    <div className="Printable_tile-bottom">
      <a
        href={image}
        download={image}
        target="_blank"
        rel="noopener noreferrer"
        >
        Download
      </a>
    </div>
  </div>;
}

export default Tile;
