import './Footer.css';

function Footer(props) {
  return (
    <footer className="App-Footer">
      <div className="Footer-Inner">
        <p className="Copyright">Copyright © 2021 by Rainbow Magic Limited</p>
        <div className="Footer-Links">
          <a href="https://www.mattel.com/privacy-statement" target="_blank" rel="noopener noreferrer">Privacy Statement</a>
          &nbsp;| <a href="https://www.mattel.com/about-cookies-and-technologies" target="_blank" rel="noopener noreferrer">Cookies & Technology</a>
          &nbsp;| <a href="https://www.mattel.com/terms-and-conditions" target="_blank" rel="noopener noreferrer">Terms & Conditions</a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
