import React from 'react';
import './SearchBar.css'

const SearchBar = ({keyword,setKeyword}) => {
  const BarStyling = {background:"white", border:"none", padding:"0.8rem"};
  return (
    <input
     className="SearchBarInput"
     style={BarStyling}
     key="random1"
     value={keyword}
     placeholder={"Type the name here"}
     onChange={(e) => setKeyword(e.target.value)}
    />
  );
}

export default SearchBar
