const Printables = [
  // Abigail
  {
    'image': 'Abigail.png',
    'name': 'Abigail',
    'tab': 2,
  },

  // Adele
  {
    'image': 'Adele.png',
    'name': 'Adele'
  },

  // Aisha
  {
    'image': 'Aisha.png',
    'name': 'Aisha'
  },

  // Adele 2.0
  {
    'image': 'Adele.png',
    'name': 'Adele 2.0'
  },

  // Aisha
  {
    'image': 'Aisha.png',
    'name': 'Aisha2'
  },

  // Adele 2.0
  {
    'image': 'Adele.png',
    'name': 'Adele 2.20'
  },

  // Aisha
  {
    'image': 'Aisha.png',
    'name': 'Aisha3'
  },

  // Adele 2.0
  {
    'image': 'Adele.png',
    'name': 'Adele 2.03'
  },

  // Aisha
  {
    'image': 'Aisha.png',
    'name': 'Aisha4'
  },

  // Adele 2.0
  {
    'image': 'Adele.png',
    'name': 'Adele 42.0'
  },

  // Cat Fairy
  {
    'image': 'Cat1.png',
    'name': 'Big Chungus',
    'tab': 1,
  },

  /* Please add new printables below. Each printable consists of:
    - 'image': the name of the image in the PRINT_IMAGES folder
    - 'name': the title of the printable content
    - 'tab': the tab that this printable item should appear on
         1: 'Coloring Pages',
         2: 'Puzzles',
         3: 'Activities'
  */
  /* DO NOT edit above this line */



  /* DO NOT edit below this line */
]

export default Printables;
