import '../Common.css';
import './FindFairy.css';

import ruby from '../images/Ruby.png'
import violet from '../images/Violet.png'
import cloud from '../images/Cloud.png';
import girl1 from '../images/Girl1.png';
import girl2 from '../images/Girl2.png';

import Tile from '../FairyIndex/Tile.jsx';
import Grid from '../Grid.jsx';
import SearchBar from './SearchBar.jsx'

import tileData from '../FAIRIES.js';

import React from 'react';

function FairyFinder() {
  const buildTiles = (data) => data.map((item) =>
    <Tile
      image={item.image}
      key={item.name}
      name={item.name}
      title={item.type}
      bio={item.description}
      series={item.series}
    />
  );

  const tiles = buildTiles(tileData);


  /* Core Search Functionality */
  const [input, setInput] = React.useState('');
  const [fairies, setFairies] = React.useState(tiles);

  const findFairy = (searchTerm) => {
    const filtered = tileData.filter(tile => {
      return tile.name.toLowerCase().includes(searchTerm.toLowerCase())
    });
    const newTiles = buildTiles(filtered);
    setInput(searchTerm);
    setFairies(newTiles);
  }

  let content = <Grid tiles={fairies} />;
  if (fairies.length === 0) {
    content = (<div className="FindFairyEmptyState">
      <img src={cloud} className="EmptyStateCloud" alt="cloud"></img>
      <div className="EmptyStateBody">
        <p>That’s such a unique name!</p>
        <p>While there is not a book with that name, we think it would make a wonderful fairy name!</p>
      </div>
      <div className="GirlWrapper">
        <img src={girl2} className="Girl" alt="Another Girl"></img>
        <img src={girl1} className="Girl" alt="Girl"></img>
      </div>
    </div>);
  }

  return <div>
    <div className="FairyWrapper FindFairyWrapper">
      <img src={ruby} className="DecorFairy" alt="glasses fairy"></img>
      <img src={violet} className="DecorFairy" alt="penguin fairy"></img>
    </div>
    <div className="ContentBackgroundWrapper FindFairyBackgroundWrapper">
      <div className="ContentBackgroundTop FindFairyBackgroundTop"></div>
      <div className="ContentBackground FindFairyBackground"></div>
    </div>
    <h1 className="ContentTitle FindFairyTitle">Find a Fairy</h1>
    <p className="FindFairy-IntroText">What is your favourite name? Check below to see if one of our fairies share that name! </p>
    <SearchBar keyword={input} setKeyword={findFairy} />
    <div className="FindFairyGridWrapper">
      {content}
    </div>
  </div>;
}

export default FairyFinder;
