import './Common.css';

function Grid({tiles}) {
  let rows = [];
  // const chunk = 4;
  // for (let i=0; i<tiles.length; i+=chunk) {
  //   rows.push(<div className="Tile-Container">
  //     {tiles.slice(i, i+chunk)}
  //   </div>);
  // }
  rows.push(<div className="Tile-Container" key="Key">
    {tiles}
  </div>);

  return <div>
    {rows}
  </div>;
}

export default Grid;
