import rochelle from '../images/Rochelle.png';
import pia from '../images/Pia.png';

import '../Common.css';
import './Printables.css'

import Tile from './Tile.jsx';
import Grid from '../Grid.jsx';

import tileData from '../PRINTABLES.js';

import React from 'react';

function Printables() {
  const fetchImage = ( name ) => {
    const module = require( `../PRINT_IMAGES/${name}` );
    // NOTE: `require` returns the full module. default returns the URL
    // TODO: May need to use `require.context`
    // https://devdesigner.xyz/dynamic-image-import-with-create-react-app/read
    return module.default;
  }

  const tabNames = {
    1: 'Coloring Pages',
    2: 'Puzzles',
    3: 'Activities'
  }

  const [curTab, setCurTab] = React.useState(1);
  const filteredTileData = tileData.filter(item => item.tab === curTab);

  const tiles = filteredTileData.map((item) =>
    <Tile
      image={fetchImage(item.image)}
      key={item.name}
      name={item.name}
    />
  );

  const tabs = Object.keys(tabNames).map(key =>
    <button
      className={`${+key === curTab ? 'PrintablesCurTab' : ''} PrintablesTab`}
      key={key}
      onClick={() => setCurTab(+key)}
    >
      <p>{tabNames[key]}</p>
    </button>
  )

  return (<div>
    <div className="FairyWrapper PrintablesFairyWrapper">
      <img src={rochelle} className="DecorFairy" alt="glasses fairy"></img>
      <img src={pia} className="DecorFairy" alt="penguin fairy"></img>
    </div>
    <div className="ContentBackgroundWrapper">
      <div className="ContentBackgroundTop PrintablesBackgroundTop"></div>
      <div className="ContentBackground PrintablesBackground"></div>
    </div>
    <h1 className="ContentTitle PrintablesTitle">Activities</h1>
    <div className="PrintablesTabContainer">
      {tabs}
    </div>
    <p className="TabTitle">{tabNames[curTab]}</p>
    <div className="PrintablesGridWrapper">
      <Grid tiles={tiles} />
    </div>
  </div>);
}

export default Printables;
